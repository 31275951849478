import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
  FilterIcon,
  ArchiveIcon,
} from "@heroicons/react/outline";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import useMortgages from "../../data/useMortgages";
import { mortgageStatusEnum } from "../../constants/enums";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAllAgencyProperties from "../../data/useAllAgencyProperties";
import Avatar from './../../components/Shared/Avatar';
import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ArchivedListBox from "../../components/Shared/ArchivedListBox";
import ActionButton from "../../components/Shared/ActionButton";
import Pagination from "../../components/Shared/Pagination";

function MortgagesRow({ mortgage, onDeleteClick, archiving, addToArchive }) {
  const navigate = useNavigate();
  const [propertyTitle, setPropertyTitle] = useState(null);
  const [propertyDepartment, setPropertyDepartment] = useState(null);
  const [formattedDepartment, setFormattedDepartment] = useState(null);
  const { undoArchive } = useMortgages();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(mortgage.id);
  };

  const handleUndoArchive = (e) => {
    e.stopPropagation();
    undoArchive.mutate([mortgage.id]);
  }

  const StatusBubble = (label, status) => {
    var className = "rounded-full px-3 py-1 text-sm font-semibold ";
    switch (status) {
      case 0: //New
        className += "bg-yellow-100 text-yellow-800";
        break;
      case 1: //Contact made
        className += "bg-blue-100 text-blue-600";
        break;
      case 2: //Confirmed
        className += "bg-blue-100 text-blue-600";
        break;
      case 3: //Completed
        className += "bg-green-100 text-green-800";
        break;
      default:
        className += "bg-yellow-100 text-yellow-800";
        break;
    }

    return <span className={className}>{label}</span>
  };

  return (
    <tr onClick={() => navigate(`mortgage/${mortgage.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell"
        onClick={(e) => {
          if (archiving) e.stopPropagation();
        }}>
        {archiving && (
          <input 
            type="checkbox" 
            className="h-4 w-4 text-etpink-600 focus:ring-etpink-500 
            border-gray-300 rounded"
            onChange={(e) => {
              addToArchive(mortgage.id, e.target.checked);
            }}
          />
        
        )}
        
        {mortgage.userId &&
          <Avatar classes="w-10 h-10" userId={mortgage.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {mortgage.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Status</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span
              className={`prose-sm rounded-full py-1 whitespace-nowrap font-bold`}
            >
              {StatusBubble(mortgageStatusEnum[mortgage.status], mortgage.status)}
            </span>
          </dd>
          <dt className="sr-only sm:hidden">Created</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {date.format(new Date(mortgage.created), "DD/MM/YYYY HH:mm")}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full py-1 whitespace-nowrap font-bold`}
        >
          {StatusBubble(mortgageStatusEnum[mortgage.status], mortgage.status)}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.emailAddress}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.telephoneNumber}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(mortgage.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.followUpDate ? date.format(new Date(mortgage.followUpDate), "DD/MM/YYYY") : "None"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          {mortgage.archivedOn !== null && (
            <ActionButton onClick={handleUndoArchive} tooltip={"Undo Archive"} Icon={ArchiveIcon} />
          )}
          <ActionButton onClick={() => {}} tooltip="View" Icon={EyeIcon} />
          <ActionButton onClick={handleDeleteClick} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function Mortgages() {
  const navigate = useNavigate();
  const { mortgages: allMortgages, remove, archive, archivedMortgages } = useMortgages();
  const { properties } = useAllAgencyProperties();
  const { deleteHandlers } = useDefaultCRUDHandlers("Mortgage");
  const [showArchived, setShowArchived] = useState(false);
  const [mortgages, setMortgages] = useState(allMortgages);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortTerm, setSortTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [isQualified, setIsQualified] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const [archiving, setArchiving] = useState(false);
  const [toArchive, setToArchive] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [nPages, setNPages] = useState(0);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const deleteMortgage = (mortgageId) => {
    setDeleteId(mortgageId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const toggleArchiving = (save = false) => {
    setArchiving(!archiving);
    if (!save) return;

    archive.mutate(toArchive, {
      onSuccess: () => {
        setToArchive([]);
      }
    });
  }

  const addToArchive = (id, checked) => {
    if (checked) {
      setToArchive([...toArchive, id]);
    } else {
      setToArchive(toArchive.filter((val) => val !== id));
    }
  }

  useEffect(() => {
    document.title = "EstateTrack Portal | Mortgages";
    if (!allMortgages.isLoading) {
      let listToFilter = showArchived ? archivedMortgages : allMortgages;

      var filtered = listToFilter.data.filter((mortgage) =>
        mortgage.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setNPages(Math.ceil(filtered.length / recordsPerPage))

      filtered = filtered.slice(indexOfFirstRecord, indexOfLastRecord);

      setMortgages(filtered);
    }
  }, [
    allMortgages.isLoading, 
    allMortgages.data, 
    properties.data, 
    properties.isLoading, 
    searchTerm, 
    sortTerm, 
    sortDirection,
    currentPage,
    showArchived,
  ]);

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  return (
    <>
      <ConfirmDelete
        itemName="Mortgage"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Mortgages</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all mortgage leads that have come in with a registration.
            </p>
          </div>
          <div className="flex px-2 mt-2 ">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FilterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
              <span>Filter</span>
            </button>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {archiving && (
              <button
                type="button"
                onClick={() => toggleArchiving(false)}
                className="inline-flex items-center justify-center rounded-md border 
                border-transparent bg-gray-400 px-4 py-2 text-sm font-medium 
                text-white shadow-sm hover:bg-gray-500 focus:outline-none 
                focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-4"
              >
                Cancel
              </button>
            )}

            <button
              type="button"
              onClick={() => toggleArchiving(archiving)}
              className="inline-flex items-center justify-center rounded-md border 
              border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium 
              text-white shadow-sm hover:bg-etpink-700 focus:outline-none 
              focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto mr-4 mt-2 "
            >
              {archiving ? "Save" : "Archive"}
            </button>

          </div>

        </div>

        {/* Filters hidden */}
        <div id="filters-section" className="pt-2 transition-all ease-in-out delay-250 duration-3000">
          {filterOpen && (
            <div className="grid grid-cols-5 gap-5">
              <ArchivedListBox setArchived={setShowArchived} />
            </div>
          )}
        </div>


        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {mortgages.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : mortgages.length > 0 ? (
            <>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {/* Avatar */}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                    >
                      <span className="flex">Name</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Status</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Email Address</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Telephone Number</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Created</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Follow Up</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {mortgages.map((mortgage) => (
                    <MortgagesRow
                      key={mortgage.id}
                      properties={properties}
                      mortgage={mortgage}
                      onDeleteClick={deleteMortgage}
                      archiving={archiving}
                      addToArchive={addToArchive}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                nPages={nPages}
              />
            </>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="mortgage"
                // onNewClick={() => navigate("")}
                />
              ) : (
                <NoItems searching itemName="mortgage" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
