import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import Dropzone from "react-dropzone";
import Compressor from 'compressorjs';
import { UploadIcon, XIcon } from "@heroicons/react/outline";

import useApiHelper from "../../services/useApiHelper";
import bytesToSize from "../../utils/bytesToSize";
import LoadingWheel from "../Shared/LoadingWheel";
import ImageCropper from "../Shared/ImageCropper";
import useUserContext from "../../contexts/UserContext";
import { useQueryClient } from "react-query";

const UploadMedia = forwardRef(({ maxFiles, editFile }, ref) => {
  const { user: { agency }, } = useUserContext();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { post, formData } = useApiHelper();
  const queryClient = useQueryClient();

  const uploadFiles = async () => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      let toUpload = [];
      files.forEach((f) => toUpload.push(uploadFile(f)));
      Promise.all(toUpload)
        .then((res) => {
          setFiles([]);
          setUploading(false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const uploadFile = (file) => {
    const url = agency ? `/media/agency/${agency.id}` : "/media";

    console.log("file", file);
    console.log("file.data", file.data);

    const newFileName = file.data.name != 'undefined' ? file.data.name : file.name;
    return new Promise((resolve, reject) => {
      post(url, {
        name: file.name,
        filename: newFileName,
        type: file.data.type,
        size: file.data.size,
        altText: file.altText,
        attributionText: file.attributionText
      })
        .then((res) => {
          let fd = new FormData();
          fd.append("media", file.data, res.data.filename);
          formData(`/media/${res.data.id}`, fd).then((_) => {
            agency ? queryClient.invalidateQueries(["media", agency?.id]) :
              queryClient.invalidateQueries(["media"]);
            resolve(res.data);
          });
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  };

  const processFiles = (acceptFiles) => {
    acceptFiles.forEach((file) => {
      if (file.type.includes("image")) {
        new Compressor(file, {
          quality: 0.7,
          success: (compressedFile) => {
            setFiles((prevFiles) => [
              {
                data: compressedFile,
                name: "",
                altText: "",
              },
              ...prevFiles,
            ]);
          }
        });
      } else {
        setFiles((prevFiles) => [
          {
            data: file,
            name: "",
            altText: "",
          },
          ...prevFiles,
        ]);
      }
    });
  };

  const removeFile = (index) => {
    if (index == openCropperIndex) toggleCropper(index);
    if (index < openCropperIndex) setOpenCropperIndex(openCropperIndex - 1);
    setFiles((prevFiles) => prevFiles.filter((f, i) => i !== index));
  };

  useImperativeHandle(ref, () => ({ upload: uploadFiles }), [files]);

  const [openCropperIndex, setOpenCropperIndex] = useState(-1);

  const toggleCropper = (index) => {
    setOpenCropperIndex(openCropperIndex == index ? -1 : index);
  }

  const urlToBlob = async (url, useProxy = true) => {
    if (useProxy) {
      url = `https://arcane-peak-78372.herokuapp.com/${url}`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const blob = await response.blob();
    return blob;
  };

  const getFilenameFromUrl = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const filenameWithExtension = pathname.substring(pathname.lastIndexOf('/') + 1);
    const filename = filenameWithExtension.substring(0, filenameWithExtension.lastIndexOf('.'));
    // Replace non-alphanumeric characters with underscores
    const cleanedFilename = decodeURIComponent(filename);
    return cleanedFilename;
  };

  const setFileToEdit = async (url) => {
    const file = {
      data: await urlToBlob(url),
      name: getFilenameFromUrl(url),
      altText: "",
    };

    console.log("File to edit:", file);

    setFiles((prevFiles) => [
      file,
      ...prevFiles,
    ]);

    toggleCropper(0);
  };

  useEffect(() => {
    if (editFile) setFileToEdit(editFile);
  }, [editFile]);

  return (
    <>
      <Dropzone onDrop={processFiles} maxFiles={maxFiles}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              className="flex flex-col items-center text-gray-500 hover:text-gray-700  bg-white border-2 border-gray-300 border-dashed rounded-lg p-10 hover:border-gray-400"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {uploading ? (
                <LoadingWheel width="w-6" height="h-6" />
              ) : (
                <>
                  <UploadIcon className="w-16 h-16 m-2" />
                  <span className="text-center">
                    Drop some files here, or click to select files
                  </span>
                </>
              )}
            </div>
          </section>
        )}
      </Dropzone>

      {openCropperIndex != -1 && (
        <div className="mt-4 group w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 overflow-hidden py-5 px-5 flex justify-center">
          <div className="w-1/2 margin-auto">
            <ImageCropper
              file={files[openCropperIndex].data}
              onClose={() => toggleCropper(openCropperIndex)}
              onComplete={(compressedFile) => {
                console.log("Compressed File:", compressedFile);
                setFiles((prevFiles) => {
                  const updatedFiles = [...prevFiles];
                  updatedFiles[openCropperIndex] = {
                    data: compressedFile,
                    name: files[openCropperIndex].name,
                    altText: files[openCropperIndex].altText,
                  };
                  return updatedFiles;
                });
                toggleCropper(openCropperIndex)
              }}
            />
          </div>
        </div>
      )}

      {files.length > 0 && (
        <ul
          role="list"
          className="bg-gray-200 my-4 p-6 rounded grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 xl:grid-cols-5"
        >
          {files.map((file, i) => (
            <li key={`media-${i}`} className="relative">
              <div className="group w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 
                overflow-hidden h-48 flex justify-center items-center">
                {file.data.type.includes("video") ? (
                  <video className="object-cover ml-auto mr-auto" controls>
                    <source
                      src={`${URL.createObjectURL(file.data)}`}
                      type={file.data.type}
                    />
                  </video>
                ) : (
                  <img
                    src={`${URL.createObjectURL(file.data)}`}
                    alt={file.altText}
                    className="object-cover ml-auto mr-auto h-full"
                  />
                )}

                {openCropperIndex != i && (
                  <span
                    onClick={() => removeFile(i)}
                    className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 inline-flex items-center p-1 rounded-full text-xs font-medium bg-red-100 text-red-800 hover:cursor-pointer hover:bg-red-200"
                  >
                    <XIcon className="w-5 h-5" />
                  </span>
                )}
              </div>
              <div className="mt-2 block">
                {openCropperIndex == i ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCropper(i);
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 
                  shadow-sm px-4 py-1.5 bg-white text-base font-medium text-gray-700 
                  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
                  focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                    Cancel
                  </button>) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCropper(i);
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent 
                  shadow-sm px-4 py-1.5 enabled:bg-etpink-600 bg-gray-500 text-base font-medium 
                  text-white enabled:hover:bg-etpink-700 focus:outline-none focus:ring-2 
                  focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm">
                    Edit Image
                  </button>
                )}
              </div>
              <p className="mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
                {bytesToSize(file.data.size)}
              </p>

              <div className="mt-2 block">
                <input
                  value={file.name}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        name: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Friendly Name"
                />
              </div>
              <div className="mt-2 block">
                <input
                  value={file.altText}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        altText: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Short Description"
                />
              </div>
              <div className="mt-2 block">
                <input
                  value={file.attributionText}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        attributionText: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Attribution Text"
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
});

export default UploadMedia;
