import { useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import { BellIcon, BriefcaseIcon, ChartSquareBarIcon, CollectionIcon, LocationMarkerIcon, OfficeBuildingIcon, PresentationChartLineIcon, PuzzleIcon, UsersIcon } from "@heroicons/react/outline";

import BranchListPartial from "../../components/Branch/BranchListPartial";
import BranchPostcodesPartial from "../../components/BranchPostcodes/BranchPostcodesPartial";
import AgencyCategoryListPartial from "../../components/Categories/AgencyCategoryListPartial";
import DepartmentListPartial from "../../components/Department/DepartmentListPartial";
import IntegrationsSettingsViewPartial from "../../components/Integrations/IntegrationSettingsViewPartial";
import NotificationProfileListPartial from "../../components/notification/NotificationPolicyListPartial";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import AgencySettingsUserListRow from "../../components/User/AgencySettings_UserListRow";
import UserListPartial from "../../components/User/UserListPartial";
import UserInviteListPartial from "../../components/UserInvite/UserInviteListPartial";
import { withAgency } from "../../data/withAgency";
import classNames from "../../utils/classNames";
import PortalQualifiers from './../Portal Qualifier/PortalQualifiers';

function _Settings({ agency, agencyReady }) {
  const { section } = useParams();

  useEffect(() => {
    document.title = "EstateTrack Portal | Settings";
  }, []);

  const getPanel = () => {
    switch (section) {
      case "categories":
        return <AgencyCategoryListPartial agencyId={agency.id} />;
      case "branches":
        return <BranchListPartial agencyId={agency.id} />;
      case "branch-postcodes":
        return <BranchPostcodesPartial agencyId={agency.id} />;
      case "departments":
        return <DepartmentListPartial agencyId={agency.id} />;
      case "users":
        return (
          <div className="divide-y divide-gray-200">
            <UserListPartial
              RowComponent={AgencySettingsUserListRow}
              agencyId={agency.id}
            />
            <UserInviteListPartial
              agencyId={agency.id}
            />
          </div>
        );
      case "integrations":
        return <IntegrationsSettingsViewPartial agencyId={agency.id} />;
      case "portalqualifier":
        return <PortalQualifiers agencyId={agency.id} />;
      case "forms":
        return <></>;
      default:
        return <NotificationProfileListPartial agencyId={agency.id} />;
    }
  };

  const subNavigation = [
    { icon: BellIcon, name: "Notification Policies", href: "" },
    { icon: PresentationChartLineIcon, name: "Categories", href: "categories" },
    {
      icon: OfficeBuildingIcon,
      name: "Branches",
      href: "branches",
    },
    {
      icon: LocationMarkerIcon,
      name: "Branch Postcodes",
      href: "branch-postcodes",
    },
    {
      icon: BriefcaseIcon,
      name: "Departments",
      href: "departments",
    },
    { icon: UsersIcon, name: "Users", href: "users" },
    {
      icon: PuzzleIcon,
      name: "Integrations",
      href: "integrations",
    },
    {
      icon: ChartSquareBarIcon,
      name: "Portal Qualifier",
      href: `portalqualifier`,
    },
    {
      icon: CollectionIcon,
      name: "System Forms",
      href: `/${agency.logicalName}/forms`,
    },
  ];

  if (!agencyReady) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-10" height="h-10" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          {/* <Breadcrumbs pages={pages} /> */}
          <div className="sm:flex-auto mb-4">
            <h3 className="text-xl font-semibold text-gray-900">Settings</h3>
            <p className="mt-2 text-sm text-gray-700">
              Manage aspects of your agency from here.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="min-h-[256px] divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => {
                    let currentSection = section ? section : "";
                    let current = item.href === currentSection;
                    return (
                      <Link
                        key={item.name}
                        to={`${item.href !== "" ? `${item.href}` : ""}`}
                        className={classNames(
                          current
                            ? "bg-etpink-50 border-etpink-500 text-etpink-700 hover:bg-etpink-50 hover:text-etpink-700"
                            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                        )}
                        aria-current={current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            current
                              ? "text-etpink-500 group-hover:text-etpink-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </Link>
                    );
                  })}
                </nav>
              </aside>
              <div className="bg-gray-50 lg:col-span-9">
                <div className="h-full py-6 px-4 sm:p-6 lg:pb-8">
                  {getPanel()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const Settings = withAgency(_Settings);

export default Settings;
