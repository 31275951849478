import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useGoogleLocations() {
  const staleTime = 1000 * 60 * 60; // 1 hour
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const locations = useQuery(["googleLocations"], () => get(`/Integration/googlelocations`).then((res) => res.data),
    {
      staleTime: staleTime,
      cacheTime: staleTime * 2,
    });


  return {
    locations
  };
}
