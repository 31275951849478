import { useEffect, useState } from "react";
import TinyMCE from "../Shared/TinyMCE";
import classNames from "../../utils/classNames";
import { get } from "react-hook-form";

export default function FormTitle({ register, isLandingPage, getValues, setValue }) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (getValues("formTitle") === "Lettings Qualifier" || getValues("formTitle") === "Sales Qualifier" || getValues("formTitle") === "Leave a Review") {
      setDisabled(true);
    }
  }, [getValues("formTitle")])

  return (
    <div className="space-y-4 bg-white shadow rounded-lg p-3 py-6">

      <div className="">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          {isLandingPage ? "Page" : "Form"} Title
        </label>
        <input
          disabled={disabled}
          type="text"
          className={classNames(disabled ? "bg-gray-200" : "", "w-full shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block sm:text-sm border-gray-300 rounded-md")}
          {...register("formTitle")}
        />
      </div>

      {isLandingPage && (
        <>
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              {isLandingPage ? "Page" : "Form"} Subtitle
            </label>
            <TinyMCE
              value={getValues("subtitle")}
              onChange={(content) => setValue("subtitle", content)}
              height={200}
            />

          </div>

          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              {isLandingPage ? "Page" : "Form"} Description
            </label>
            <TinyMCE
              value={getValues("description")}
              onChange={(content) => setValue("description", content)}
              height={300}
            />

          </div>
        </>
      )}

    </div>
  )
}