import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { ClipboardCheckIcon, ClipboardCopyIcon, ClipboardListIcon } from "@heroicons/react/outline";

import TinyMCE from "../../components/Shared/TinyMCE";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import classNames from "../../utils/classNames";
import useDefaultPolicies from "../../data/useDefaultPolicies";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';


function PolicyEdit({ type, policy, update }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Policy");
  const [content, setContent] = useState(policy);

  const updatePolicy = () => {
    let body = {
      policy: content
    };

    update.mutate(body, saveHandlers);
  };

  useEffect(() => {
    document.title = `EstateTrack Portal | Edit ${type} Policy`

    setContent(policy);
  }, [type, policy])

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-xl font-medium text-gray-900">Edit Default Policy<span className="text-sm text-gray-500"></span></h3>
        <p className="mt-1 text-sm text-gray-500">
          Change the default {type} policy for all agencies.
        </p>
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => updatePolicy()}
          className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
        >
          Save
        </button>
      </div>

      <div>
        <TinyMCE
          value={content}
          onChange={setContent}
          height={650}
        />
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => updatePolicy()}
          className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default function PolicyManager() {
  const { policies, updatePrivacy, updateTerms, updateCookies } = useDefaultPolicies();
  const { section } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!policies.isLoading && policies.data) {
      console.log(policies.data);
      setIsLoading(false);
    }
    //Load in current policies
  }, [policies.isLoading, policies.data])

  const getPanel = () => {
    switch (section) {
      case "terms":
        return <PolicyEdit type={"terms"} policy={policies.data?.termsAndConditions} update={updateTerms} />;
      case "cookies":
        return <PolicyEdit type={"cookies"} policy={policies.data?.cookiePolicy} update={updateCookies} />;
      default:
        return <PolicyEdit type={"privacy"} policy={policies.data?.privacyPolicy} update={updatePrivacy} />;
    }
  };

  const subNavigation = () => {
    return [
      { icon: ClipboardListIcon, name: "Privacy Policy", href: "" },
      { icon: ClipboardCheckIcon, name: "Terms & Conditions", href: "terms" },
      { icon: ClipboardCopyIcon, name: "Cookie Policy", href: "cookies", },
    ];
  };


  if (isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-10" height="h-10" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">

          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="min-h-[256px] divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-2">
                <nav className="space-y-1">
                  {subNavigation().map((item) => {
                    let currentSection = section ? section : "";
                    let current = item.href === currentSection;
                    return (
                      <Link
                        key={item.name}
                        to={`${item.href !== "" ? `${item.href}` : ""}`}
                        className={classNames(
                          current
                            ? "bg-etpink-50 border-etpink-500 text-etpink-700 hover:bg-etpink-50 hover:text-etpink-700"
                            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                        )}
                        aria-current={current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            current
                              ? "text-etpink-500 group-hover:text-etpink-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </Link>
                    );
                  })}
                </nav>
              </aside>
              <div className="bg-gray-50 lg:col-span-10">
                <div className="h-full py-6 px-4 sm:p-6 lg:pb-8">
                  {getPanel()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
