
import React, { useEffect, useState } from "react";
import LoadingWheel from "../../components/Shared/LoadingWheel";

import { withAgency } from "../../data/withAgency";
import useReports from "../../data/useReports";

import ValuationYearGraph from "../../components/Analytics/Valuations/ValuationYearGraph";
import ValuationReferrersTable from "../../components/Analytics/Valuations/ValuationReferrersTable";
import ValuationsReferrersPie from "../../components/Analytics/Valuations/ValuationsReferrersPie";
import LiveValuations from "../../components/Analytics/Valuations/LiveValuations";
import useValuationReports from "../../data/useValuationReports";
import BranchListBox from "../../components/Shared/BranchListBox";

function _ValuationsAnalytics({ agencyReady, agency }) {
  const { valuationsStats } = useValuationReports(agency?.id);
  const [isTotal, setIsTotal] = useState(false);
  const [branch, setBranch] = useState(null);
  const [valStats, setValStats] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (agencyReady && !valuationsStats.isLoading && valuationsStats.data) {
      setIsLoading(true);

      if (branch === null) {
        const overallValStats = valuationsStats.data.find((val) => val.branchId === null);
        setValStats(overallValStats);
      } else {
        const branchValStats = valuationsStats.data.find((val) => val.branchId === branch.id);
        console.log(branchValStats)
        setValStats(branchValStats);
      }

      setIsLoading(false);
    }
  }, [agency, agencyReady, valuationsStats.data, valuationsStats.isLoading, isTotal, branch])

  return (
    <div className="px-12">
      <div className="sm:flex sm:items-center justify-between mb-6">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Valuations Analytics</h1>
          <p className="mt-2 text-sm text-gray-700">
            Details about the valuations, including their status', sources and more.
          </p>
        </div>
        <div className="flex gap-x-4">
          <div className="w-48">
            <BranchListBox setBranch={setBranch} />
          </div>
          <div className="mt-6">
            <select
              className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"
              onChange={(e) => setIsTotal(e.target.value === "true")}
            >
              <option value={"false"}>Last 30 Days</option>
              <option value={"true"}>All</option>
            </select>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >

        {valuationsStats.isLoading || isLoading ? (
          <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Valuations" : "Valuations "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? valStats.totalValuations : valStats.valuationLeads}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Change in last 30 days
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {valStats.leadsPercentage > 0 && "+"} {valStats.leadsPercentage}%
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Valuations Won" : "Won "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? valStats.totalWon : valStats.totalWonMonth}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Valuations Lost" : "Lost "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? valStats.totalLost : valStats.totalLostMonth}
              </dd>
            </div>
          </>
        )}
      </div>

      {valuationsStats.isLoading || isLoading ? (
        <div>
        </div>
      ) : (

        <div className="py-4 mt-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">
          <div className="shadow bg-white rounded-lg">
            <ValuationsReferrersPie stats={valStats} />
          </div>

          <div className="col-span-1 xl:col-span-1 2xl:col-span-2 shadow bg-white rounded-lg">
            <ValuationReferrersTable stats={valStats} />
          </div>

          <div className="shadow bg-white rounded-lg">
            <LiveValuations valuations={valuationsStats} branchId={branch?.id} />
          </div>

          <div className="col-span-1 xl:col-span-1 2xl:col-span-2 shadow bg-white rounded-lg">
            <ValuationYearGraph stats={valStats} />
          </div>

        </div>
      )}





    </div>
  )

}


export const ValuationsAnalytics = withAgency(_ValuationsAnalytics);