import React, { useState, useCallback, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import Compressor from "compressorjs";


// Function to resize the image using a scale factor and return a new Blob
const resizeImage = (file, scale) =>
  new Promise((resolve) => {
    console.log(file, scale);
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        // console.log("Resizing image to:", canvas.width, "x", canvas.height);
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => resolve({ blob, width: canvas.width, height: canvas.height }), "image/jpeg");
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });

const ImageCropper = ({ file, files, setFiles, fileIndex, onClose, onComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [useNativeAspectRatio, setUseNativeAspectRatio] = useState(true);
  const [blobUrl, setBlobUrl] = useState(null);
  const isMounted = useRef(true);
  const [scale, setScale] = useState(100);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  // Load and scale the image whenever the scale changes or a new file is provided
  useEffect(() => {
    // console.log("resizing", file)
    // console.log("resizing", scale)
    if (file) {
      resizeImage(file, scale / 100).then(({ blob, width, height }) => {
        setImageDimensions({ width, height });
      });
    }

    return () => {
      setImageDimensions({ width: 0, height: 0 });
    }
  }, [file, scale]);

  // Calculate the aspect ratio when the file is loaded
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          if (isMounted.current) {
            const aspect = image.width / image.height;
            setAspectRatio(aspect);
          }
        };
        image.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  // Create a Blob URL for the file and clean up when the component unmounts or file changes
  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setBlobUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  // Set isMounted to false when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    try {
      // console.log("Getting cropped image...");
      // Get the cropped image as a blob
      // const croppedImage = await getCroppedImg(URL.createObjectURL(file), croppedAreaPixels);
      console.log("file name", file.name);
      const croppedImage = await getCroppedImg(URL.createObjectURL(file), croppedAreaPixels);
      // console.log("Scaling cropped image")
      const scaledCroppedImage = await resizeImage(croppedImage, scale / 100);

      // console.log("Compressing cropped image...", scaledCroppedImage);
      // Compress the cropped image using Compressor.js

      new Compressor(scaledCroppedImage.blob, {
        quality: 0.7,
        success: (compressedBlob) => {
          if (isMounted.current) {
            // Create a new File object with the original name
            const compressedFile = new File([compressedBlob], file.name, {
              type: compressedBlob.type,
              lastModified: Date.now(),
            });
            console.log("compressed scaled file", compressedFile);
            console.log("compressed scaled file name", compressedFile.name);

            onComplete(compressedFile);
            onClose();
          }
        },
      });
    } catch (error) {
      if (isMounted.current) {
        console.error("Error cropping the image:", error);
      }
    }
  };

  const toggleAspectRatio = () => {
    setUseNativeAspectRatio(!useNativeAspectRatio);
  }

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "500px" }}>
        {blobUrl && (
          <Cropper
            image={blobUrl}
            crop={crop}
            zoom={zoom}
            aspect={useNativeAspectRatio ? aspectRatio : 1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        )}
        <small className="absolute left-0 bottom-0 py-1 px-2 text-white bg-black/20">
          {imageDimensions.width} x {imageDimensions.height}
        </small>
      </div>
      <div className="w-full">
        <input
          type="range"
          min="10"
          max="100"
          value={scale}
          className="w-full"
          onChange={(e) => setScale(Number(e.target.value))}
        />

      </div>
      <div className="flex mt-2 gap-2">
        <button
          onClick={toggleAspectRatio}
          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Aspect
        </button>
        <button
          onClick={onClose}
          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
        <button
          onClick={handleCropSave}
          className="w-full inline-flex justify-center rounded-md border border-transparent 
          shadow-sm px-4 py-2 enabled:bg-etpink-600 bg-gray-500 text-base font-medium 
          text-white enabled:hover:bg-etpink-700 focus:outline-none focus:ring-2 
          focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm">
          Apply
        </button>
      </div>
    </>

  );
};

const getCroppedImg = async (imageSrc, pixelCrop) => {
  // console.log("Get Cropped: creating image...");
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  // console.log("Get Cropped: drawing image...");
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );
  // console.log("Get Cropped: returning promise...");
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return reject(new Error("Canvas is empty"));
      }
      resolve(blob); // Return cropped image as a Blob
    }, "image/jpeg");
  });
};

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    // console.log("Create Image: creating image...");
    const image = new Image();
    // console.log("Create Image: setting event listeners...");
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    // console.log("Create Image: setting image attributes...");
    image.setAttribute("crossOrigin", "anonymous"); // To avoid CORS issues
    image.src = url;
  });
}


export default ImageCropper;
