import { useState } from "react";

import ReviewConfigModal from "./ReviewConfigModal";
import UpdateReviewConfigModal from "./UpdateReviewConfigModal";
import { PlusIcon, RefreshIcon, TrashIcon } from "@heroicons/react/outline";
import LoadingWheel from "../Shared/LoadingWheel";
import useReviewSites from "../../data/useReviewSites";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { reviewSiteEnum } from "../../constants/enums";
import ConfirmDelete from "../Shared/ConfirmDelete";
import useApiHelper from "../../services/useApiHelper";
import { ButtonInput } from './../Shared/Inputs/ButtonInput';
import ImportReviewsFromCsv from "./ImportReviewsFromCsv";

function ReviewSiteListRow({ site, setConfirmDeleteOpen, setDeleteId, handleUpdate }) {

  const handleDeleteClick = (e, siteId) => {
    e.stopPropagation();
    setDeleteId(siteId);
    setConfirmDeleteOpen(true);
  }

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:cursor-pointer hover:bg-gray-100" onClick={() => handleUpdate(site)}>
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600 md:w-1/4">
          {reviewSiteEnum[site.siteName]}
          {site.reviewBranch && ` - ${site.reviewBranch}`}
        </p>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={(e) => handleDeleteClick(e, site.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Remove</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function ReviewConfig({ agencyId, agencyLogicalName }) {
  const { reviewSites, add, remove, update, importReviews } = useReviewSites(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Review site configuration");
  const { post } = useApiHelper();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [site, setSite] = useState(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [calculating, setCalculating] = useState(false);

  const handleCalculate = () => {
    setCalculating(true);

    post(`/review/${agencyId}/retrieve`, {})
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    setCalculating(false);
  }

  const handleImport = () => {
    importReviews.mutate(undefined, saveHandlers);
  }

  const handleAddReviewSite = (addedReviewSite) => {
    add.mutate(addedReviewSite, saveHandlers);
  };

  const handleRemoveReviewSite = () => {
    remove.mutate(deleteId, deleteHandlers);
    setConfirmDeleteOpen(false);
  };

  const handleUpdateReviewSite = (updateReviewSite) => {
    update.mutate(updateReviewSite, saveHandlers)
  }

  const handleUpdate = (site) => {
    setIsUpdate(true);
    setSite(site);
    setOpenUpdate(true);
  }

  return (
    <>
      <ImportReviewsFromCsv agencyId={agencyId} agencyLogicalName={agencyLogicalName} open={openImport} setOpen={setOpenImport} />
      <ConfirmDelete
        itemName="Review site configuration"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleRemoveReviewSite}
      />
      <ReviewConfigModal open={open} setOpen={setOpen} handleAddReviewSite={handleAddReviewSite} currentReviewSites={reviewSites} />
      <UpdateReviewConfigModal open={openUpdate} setOpen={setOpenUpdate} site={site} handleUpdateReviewSite={handleUpdateReviewSite} />
      <div>
        <h3 className="text-xl font-medium text-gray-900">Review Sites</h3>
        <p className="mt-1 text-sm text-gray-500">
          These are the websites that we support with connecting your reviews
        </p>

        <div className="flex justify-between">
          <div>
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              Add Review Site
            </button>
          </div>

          <div className="flex items-center gap-x-4">
            <button
              type="button"
              onClick={() => handleImport()}
              className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
            >
              <RefreshIcon className="w-4 h-4 mr-2" />
              Import Reviews
            </button>
            <ButtonInput label="Import from CSV" isSubmit={false} onClick={() => setOpenImport(true)} />
          </div>
        </div>

        <ul className="mb-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
          {reviewSites.isLoading ? (
            <li key="userInvites-loading" className="py-4 flex justify-center">
              <LoadingWheel width="w-8" height="h-8" />
            </li>
          ) : reviewSites.data.length > 0 ? (
            reviewSites.data.map((site, i) => (
              <ReviewSiteListRow
                site={site}
                key={i}
                setConfirmDeleteOpen={setConfirmDeleteOpen}
                setDeleteId={setDeleteId}
                handleUpdate={handleUpdate}
              />
            ))
          ) : (
            <li>
              <div className="py-5 text-center text-sm text-gray-400">
                No Review Sites Configured
              </div>
            </li>
          )}
        </ul>
      </div>
      <div>
        <h3 className="text-lg font-medium text-gray-900">Review Site Formatting</h3>
        <p className="mt-1 text-sm text-gray-500">
          These are the formatting rules for the review sites
        </p>

        <dl className="pt-4 flex flex-col sm:ml-3 sm:mt-0 gap-y-2 divide-y">

          <div className="flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">Google |</dt>
            <dd className="font-medium">API Key</dd>
            <dd className="text-gray-500 underline">
              <a href="https://console.cloud.google.com/projectselector2/google/maps-apis/credentials?utm_source=Docs_Credentials" target="_blank">
                Google Cloud Credentials
              </a>
            </dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">Google |</dt>
            <dd className="font-medium">Place ID</dd>
            <dd className="text-gray-500 underline">
              <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" target="_blank">
                Place ID Finder
              </a>
            </dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">TrustPilot |</dt>
            <dd className="font-medium">URL</dd>
            <dd className="text-gray-500">https://uk.trustpilot.com/review/youragencyhere.com</dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">Feefo |</dt>
            <dd className="font-medium">Merchant ID</dd>
            <dd className="text-gray-500 underline">
              <a href="https://support.feefo.com/support/solutions/articles/8000090040-finding-your-merchant-identifier" target="_blank">
                How to find your Merchant ID
              </a>
            </dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">Estas |</dt>
            <dd className="font-medium">Branch</dd>
            <dd className="text-gray-500">Use the search bar to find your required branch</dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">AllAgents |</dt>
            <dd className="font-medium">Firm Link Whole Agency</dd>
            <dd className="text-gray-500">https://www.allagents.co.uk/your-agency-here.co.uk</dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">AllAgents |</dt>
            <dd className="font-medium">Firm Link Specific Branch</dd>
            <dd className="text-gray-500">https://www.allagents.co.uk/your-agency-here.co.uk/branch-name</dd>
          </div>

          <div className="pt-2 flex items-center text-sm text-gray-900 gap-x-2">
            <dt className="font-medium">Any Site |</dt>
            <dd className="font-medium">Request Review Link</dd>
            <dd className="text-gray-500">A link to leave a review for that Agency/Branch for that specific review site</dd>
          </div>

        </dl>

      </div>
    </>
  );
}