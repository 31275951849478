import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import useAgencyBranches from "../../data/useAgencyBranches";
import useApexBranches from "../../data/useApexBranches";
import useBranchMappings from "../../data/useBranchMappings";
import { ButtonInput } from "../Shared/Inputs/ButtonInput";
import LoadingWheel from "../Shared/LoadingWheel";
import SelectInput from './../Shared/Inputs/SelectInput';
import Modal from './../Shared/Modal';
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ActionButton from "../Shared/ActionButton";
import { XIcon } from "@heroicons/react/outline";

export default function ApexBranchMappingModal({ open, setOpen, integration }) {
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Branch Mapping");
  const { branches: apexBranches } = useApexBranches(integration.agencyId);
  const { branches } = useAgencyBranches(integration.agencyId);
  const { branchMappings, add, remove } = useBranchMappings(integration.id, integration.agencyId);
  const [isLoading, setIsLoading] = useState(true);
  const [missing, setMissing] = useState(0);
  const [apexBranchesLeft, setApexBranchesLeft] = useState([]);

  useEffect(() => {
    if (!apexBranches.isLoading && !branchMappings.isLoading && !branches.isLoading) {

      //take number of apex branches and subtract the number of mappings
      setMissing(apexBranches.data.length - branchMappings.data.length);

      //set the apex branches that are not mapped
      let mappedBranchIds = branchMappings.data.map(b => b.externalBranchId);
      let left = apexBranches.data.filter(b => !mappedBranchIds.includes(b.id.toString()));
      setApexBranchesLeft(left);

      setIsLoading(false);
    }
  }, [apexBranches.isLoading, branchMappings.isLoading, branchMappings.data, branches.isLoading])

  const onSubmit = (data) => {
    if (!data.branchId || !data.externalBranchId) return;

    const branch = branches.data.find(b => b.id === data.branchId);
    const externalBranch = apexBranches.data.find(b => b.id === data.externalBranchId);

    if (!branch || !externalBranch) return;

    let body = {
      branchId: branch.id,
      externalBranchId: externalBranch.id.toString(),
      externalBranchName: externalBranch.name,
    };

    add.mutate(body, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        reset();
      }
    });
  };

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-2xl"}>
      <>
        {isLoading ? (
          <LoadingWheel width="w-8" height="h-8" />
        ) : (
          <div>
            <h1 className="text-lg font-semibold">Branch Mappings</h1>
            <hr className="mb-4" />

            {branchMappings.data.length === 0 ? (
              <div>
                <h2>No Branch Mappings</h2>
              </div>
            ) : (
              <div>

                {missing > 0 && (
                  <div>
                    <h2 className="text-red-600 font-semibold">Missing Branches: {missing}</h2>
                  </div>
                )}

                <div className="flex justify-between">
                  <h2 className="font-semibold">Estatetrack Branch</h2>
                  <h2 className="font-semibold">Apex Branch</h2>
                </div>

                {branchMappings.data.map((mapping) => {
                  return (
                    <div key={mapping.id} className="flex items-center justify-between">
                      <h2>{mapping.branch.name}</h2>
                      <h2 className="grow mx-4 border-b border-dashed"></h2>
                      <div className="flex items-center gap-x-2">
                        <h2>{mapping.externalBranchName}</h2>
                        <ActionButton tooltip="Delete Mapping" onClick={() => remove.mutate(mapping.id, deleteHandlers)} Icon={XIcon} />
                      </div>
                    </div>
                  )
                })}

              </div>
            )}


            <hr className="mt-6" />

            <form onSubmit={handleSubmit(onSubmit)} className="mt-2 space-y-4">
              <h1 className="font-semibold">New Mapping</h1>
              <div className="space-y-2">
                <SelectInput label="Estatetrack Branch" register={register} registerName="branchId" setValue={setValue} watch={watch} options={branches.data} />
                <SelectInput label="Apex Branches Left to Map" register={register} registerName="externalBranchId" setValue={setValue} watch={watch} options={apexBranchesLeft} />
              </div>
              <ButtonInput label="Save" isSubmit onClick={() => { }} classes="" />
            </form>
          </div>
        )}
      </>
    </Modal>
  )
}