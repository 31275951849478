import { useState } from "react";
import { useEffect } from "react";

import useAgencyMedia from "../../data/useAgencyMedia";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import bytesToSize from "../../utils/bytesToSize";
import classNames from "../../utils/classNames";
import LoadingWheel from "../Shared/LoadingWheel";
import { InformationCircleIcon } from "@heroicons/react/solid";

export default function MediaManager({
  mediaType = "all",
  multiple = false,
  selected,
  onSelect,
  editFile,
  search
}) {
  const { media: allMedia, markDeleted } = useAgencyMedia();
  const { deleteHandlers } = useDefaultCRUDHandlers("Media");
  const [media, setMedia] = useState([]);
  const [resolutions, setResolutions] = useState({});

  useEffect(() => {
    if (!allMedia.isLoading) {
      switch (mediaType) {
        case "video":
          setMedia(allMedia.data.filter((am) => am.type.includes("video") && am.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => new Date(b.created) - new Date(a.created)));
          break;
        case "image":
          setMedia(allMedia.data.filter((am) => !am.type.includes("video") && am.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => new Date(b.created) - new Date(a.created)));
          break;
        case "all":
        default:
          setMedia(allMedia.data.sort((a, b) => new Date(b.created) - new Date(a.created)));
          break;
      }
    }
  }, [allMedia.data, allMedia.isLoading, mediaType, search]);

  useEffect(() => {
    if(media.length == 0) return;
    media.map(async (m, i) => {
      if(m.dateDeleted) return;
      // console.log("Calculating resolution:", m.path);

      await getImageResolutionFromUrl(`${CDN_URL}${m.path}`, i).then((res) => {
        // console.log(`Result for ${i}`, res);

        setResolutions((prevResolutions) => ({
          ...prevResolutions,
          [i]: `${res.width}x${res.height}`,
        }));

        // setResolutions([...resolutions, `${res.width}x${res.height}`]);
      });
    });
  }, [media]);

  const handleDelete = (mediaToRemove) => {
    if (window.confirm("Are you sure you want to remove this piece of media?")) {
      markDeleted.mutate(mediaToRemove.id, deleteHandlers);
      media.filter((m) => m.id !== mediaToRemove.id);
    }
  }

  const getImageResolutionFromUrl = (url, index, useProxy = true) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (useProxy) {
          url = `https://arcane-peak-78372.herokuapp.com/${url}`;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.statusText}`);
        }
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
          const dimensions = { width: img.width, height: img.height, index };
          URL.revokeObjectURL(img.src); // Clean up the object URL
          resolve(dimensions);
        };
        img.onerror = (error) => {
          reject(error);
        };
      } catch (error) {
        reject(error);
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {allMedia.isLoading ? (
        <div className="py-6 flex justify-center">
          <LoadingWheel width="w-8" height="h-8" />
        </div>
      ) : media.length > 0 ? (
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 xl:grid-cols-5"
        >
          {media.map((m, i) => {
            let isSelected = selected
              ? multiple
                ? selected.some((s) => s.id === m.id)
                : selected.id === m.id
              : false;

            
            return (
              !m.dateDeleted &&
              <li key={m.id} className="relative">
                <div
                  onClick={() => onSelect(m)}
                  className={classNames(
                    "rounded-lg hover:bg-gray-200",
                    isSelected && "bg-etpink-200 hover:bg-etpink-300"
                  )}
                >
                  <div className="w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 
                    overflow-hidden h-48 flex justify-center items-center relative">
                    {m.type.includes("video") ? (
                      <video className="object-cover ml-auto mr-auto" controls>
                        <source src={`${CDN_URL}${m.path}`} type={m.type} />
                      </video>
                    ) : (
                      <img
                        src={`${CDN_URL}${m.path}`}
                        alt={m.altText}
                        className="object-contain ml-auto mr-auto h-full"
                      />
                    )}
                    <div className="w-6 h-6 absolute top-1 right-1 group">
                      <InformationCircleIcon 
                        className="absolute top-0 right-0 h-6 w-6 text-etpink-600 bg-white 
                        rounded-full group-hover:bg-black group-hover:cursor-pointer z-10" 
                      />
                      <div className="absolute top-2 right-2 bg-white text-black rounded-md px-2 py-1
                        text-left overflow-hidden h-0 w-0 transition-size duration-300
                        group-hover:w-44 group-hover:h-20">
                        <p className="opacity-0 group-hover:opacity-100 transition-opacity duration-700"><small>Size: <span className="text-etpink-600 font-semibold">{bytesToSize(m.size)}</span></small></p>
                        <p className="opacity-0 group-hover:opacity-100 transition-opacity duration-700"><small>Resolution: <span className="text-etpink-600 font-semibold">{i in resolutions && resolutions[i]}</span></small></p>
                        <p className="opacity-0 group-hover:opacity-100 transition-opacity duration-700"><small>Added: <span className="text-etpink-600 font-semibold">{formatDate(m.created)}</span></small></p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="min-h-[70px]">
                    <p className="mt-2 px-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                      {m.name}
                    </p>
                    <div className="w-3/4">
                      <p className="block px-2 text-sm font-medium text-gray-600 pointer-events-none">
                        {m.altText}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-end">
                    <button
                      type="button"
                      onClick={() => editFile(`${CDN_URL}${m.path}`)}
                      className="mr-2 px-5 py-2 rounded-md border border-transparent shadow-sm enabled:bg-etpink-600 bg-gray-500 text-base font-medium text-white enabled:hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm">
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(m)}
                      className="px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400">
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            );

          })}
        </ul>
      ) : (
        <div className="bg-white border rounded text-center text-gray-400 px-4 py-6">
          No Media
        </div>
      )}
    </>
  );
}
