import { useEffect, useState } from "react";

import { PlusIcon, TrashIcon } from "@heroicons/react/outline";

import useIntegrations from "../../data/useIntegrations";
import useMeta from "../../data/useMeta";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useFacebookSdk from "../../hooks/useFacebookSdk";
import ConfirmDelete from "../Shared/ConfirmDelete";
import LoadingWheel from "../Shared/LoadingWheel";
import { ButtonInput } from './../Shared/Inputs/ButtonInput';
import IntegrationsModal from "./IntegrationsModal";
import ActionTextButton from "../Shared/ActionTextButton";
import ActionButtonContainer from "../Shared/ActionButtonContainer";
import ActionButton from "../Shared/ActionButton";
import ApexBranchMappingModal from "./ApexBranchMappingModal";

function IntegrationsListRow({ integration, setConfirmDeleteOpen, setDeleteId, handleUpdate }) {
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleDeleteClick = (e, integrationId) => {
    e.stopPropagation();
    setDeleteId(integrationId);
    setConfirmDeleteOpen(true);
  }

  const handleMapBranch = (e) => {
    e.stopPropagation();
    setOpenUpdate(true);
  }

  return (
    <>
      <ApexBranchMappingModal open={openUpdate} setOpen={setOpenUpdate} integration={integration} />
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:cursor-pointer hover:bg-gray-100" onClick={() => handleUpdate(integration)}>
        <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
          <p className="flex-shrink-0 text-sm font-medium text-etpink-600 md:w-1/4">
            {integration.appName}
          </p>
        </div>

        <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
          <ActionButtonContainer>
            {
              integration.appName === "Apex27" && (
                <ActionTextButton onClick={handleMapBranch} text="Map Branches" />
              )
            }
            <ActionButton onClick={(e) => handleDeleteClick(e, integration.id)} tooltip="Delete" Icon={TrashIcon} />
          </ActionButtonContainer>
        </div>
      </li>
    </>
  );
};

function MetaPageConfigsListRow({ pageConfig, setConfirmDeleteOpen, setDeleteId }) {

  const handleDeleteClick = (e, pageConfigId) => {
    e.stopPropagation();
    setDeleteId(pageConfigId);
    setConfirmDeleteOpen(true);
  }

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:bg-gray-100">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600">
          {pageConfig.pageName} - Facebook Page ID: {pageConfig.pageId} {pageConfig.instagramUserId ? ` - Instagram ID: ${pageConfig.instagramUserId}` : ""}
        </p>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <ActionButtonContainer>
          <ActionButton onClick={(e) => handleDeleteClick(e, pageConfig.id)} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </div>
    </li>
  );
};


export default function IntegrationsSettingsPartial({ agencyId }) {
  const { integrations, add, update, remove } = useIntegrations(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Integration configuration");
  const { initFacebookSdk, getFacebookLoginStatus, fbLogin, fbMe, reRequestPermissions } = useFacebookSdk();
  const { metaPageConfigs, remove: metaRemove, setup } = useMeta(agencyId);
  const [agency, setAgency] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmMetaDeleteOpen, setConfirmMetaDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteMetaId, setDeleteMetaId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [integration, setIntegration] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleAddIntegration = (addedIntegration) => {
    add.mutate(addedIntegration, saveHandlers);
  };

  const handleRemoveIntegration = () => {
    remove.mutate(deleteId, deleteHandlers);
    setConfirmDeleteOpen(false);
  };

  const handleUpdateIntegration = (updatedIntegration) => {
    update.mutate(updatedIntegration, saveHandlers)
  }

  const handleUpdate = (integration) => {
    setIsUpdate(true);
    setIntegration(integration);
    setOpen(true);
  }

  const handleAdd = () => {
    setIsUpdate(false);
    setOpen(true);
  }

  const handleRemoveMetaPageConfig = () => {
    metaRemove.mutate(deleteMetaId, deleteHandlers);
    setConfirmMetaDeleteOpen(false);
  };

  const handleFacebookLogin = () => {

    fbLogin().then((response) => {
      console.log(response);
      if (response.status === "connected") {
        console.log("Person is connected");
        //response contains user token
        setup.mutate(response.authResponse, saveHandlers);
      } else {
        console.log("Person is not connected");
      }
    });

    // fbMe().then((response) => {
    //   console.log(response);
    // });
  };


  useEffect(() => {
    // console.log("Started use effect");
    initFacebookSdk().then(() => {
      getFacebookLoginStatus().then((response) => {
        if (response.authResponse == null) {
          console.log("No login status for the person");
        } else {
          console.log(response);
        }
      });
    });
  }, []);

  // useEffect(() => {
  //   if (agencyId && !agencies.isLoading) {
  //     let agency = agencies.data.find((a) => a.id === agencyId);

  //     setAgency(agency);
  //   }

  //   setIsLoading(false);
  // }, [agencies.data, agencies.isLoading, agencyId])

  if (integrations.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <ConfirmDelete
          itemName="Portal Qualifier Configuration"
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={handleRemoveIntegration}
        />
        <ConfirmDelete
          itemName="Meta Page Configuration"
          open={confirmMetaDeleteOpen}
          setOpen={setConfirmMetaDeleteOpen}
          onConfirm={handleRemoveMetaPageConfig}
        />
        <IntegrationsModal open={open} setOpen={setOpen} integration={integration} isUpdate={isUpdate} handleAddIntegration={handleAddIntegration} handleUpdateIntegration={handleUpdateIntegration} />

        <div className="">
          <div className="-mx-6 px-6">
            <h3 className="text-xl font-medium text-gray-900">
              Integration Settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Settings for managing your integrations
            </p>
          </div>
          <button
            type="button"
            onClick={() => handleAdd()}
            className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add Integration
          </button>
          <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
            {(integrations.isLoading || metaPageConfigs.isLoading) ? (
              <li className="py-4 flex justify-center">
                <LoadingWheel width="w-8" height="h-8" />
              </li>
            ) : (integrations.data?.length > 0 || metaPageConfigs.data?.data?.length > 0) ? (
              <>
                <div className="divide-y divide-gray-200">
                  {integrations.data?.map((integration, i) => (
                    <IntegrationsListRow
                      integration={integration}
                      key={i}
                      setConfirmDeleteOpen={setConfirmDeleteOpen}
                      setDeleteId={setDeleteId}
                      handleUpdate={handleUpdate}
                    />
                  ))}
                </div>
                <div className="divide-y divide-gray-200">
                  {metaPageConfigs.data?.data?.map((metaPageConfig, i) => (
                    <MetaPageConfigsListRow
                      pageConfig={metaPageConfig}
                      key={i}
                      setConfirmDeleteOpen={setConfirmMetaDeleteOpen}
                      setDeleteId={setDeleteMetaId}
                    />
                  ))}
                </div>
              </>
            ) : (
              <li>
                <div className="py-5 text-center text-sm text-gray-400">
                  No Integrations Configured
                </div>
              </li>
            )}
          </ul>
          <div>
          </div>

          <ButtonInput label="Login with Facebook" onClick={() => handleFacebookLogin()} isSubmit={false} />
          {/* <ButtonInput label="Re Request Facebook Permissions" onClick={() => handleReRequestPermissions()} isSubmit={false} /> */}
        </div>
      </>
    );
  }
}
