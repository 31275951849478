import { useCallback, useEffect, useState } from "react";

import date from "date-and-time";
import { useForm, useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import TinyMCE from "../../components/Shared/TinyMCE";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useAgencyMedia from "../../data/useAgencyMedia";
import useReviews from "../../data/useReviews";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import { ButtonInput } from './../../components/Shared/Inputs/ButtonInput';
import ReplyToReviewModal from "../../components/Review/ReplyToReviewModal";
import Card from "../../components/Shared/Card/Card";
import useUserContext from "../../contexts/UserContext";


export default function ReviewAddEdit() {
  const { user: { agency } } = useUserContext();
  const { media } = useAgencyMedia();
  const queryClient = useQueryClient();
  const { reviewId } = useParams();
  const { reviews, add, update, reply } = useReviews(0);
  const navigate = useNavigate();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: { status: false, source: "Manual", datetime: date.format(new Date(), "YYYY-MM-DD"), content: "", rating: 4.5 }
  });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Review");
  const { saveHandlers: replyHandlers } = useDefaultCRUDHandlers("Review Reply");
  const [review, setReview] = useState(undefined);
  const [content, setContent] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [rating, setRating] = useState();
  const [loading, setLoading] = useState(true);
  const [openReply, setOpenReply] = useState(false);

  const loadReview = useCallback(
    (review) => {
      setReview(review);

      setContent(review.content);

      reset({
        source: review.site,
        name: review.name,
        url: review.reviewUrl,
        datetime: date.format(new Date(review.date), "YYYY-MM-DD"),
        content: review.content,
        rating: review.starRating,
        status: review.status === 0 ? true : false,
        featuredMedia: review.avatar,
      });

      setRating(review.starRating);
      setLoading(false);
    },
    [reset]
  );

  const handleRating = (e) => {
    setRating(e.target.value);
    setValue("rating", e.target.value);
  }

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("media");

    setTimeout(() => {
      setValue("featuredMedia", media.id, { shouldDirty: true });
      setFeaturedMediaOpen(false);
    }, 1000);
  };


  const replyToReview = (replyText) => {
    // console.log(reply);
    reply.mutate(
      {
        reviewId: reviewId,
        body: {
          reply: replyText,
        }
      }, {
      onSuccess: () => {
        replyHandlers.onSuccess();
        setOpenReply(false);
        loadReview(review);
      },
      onError: () => {
        replyHandlers.onError();
      }
    }
    );
  }

  const onSubmit = (data) => {

    setLoading(true);
    var status = 3;
    if (!isDraft) {
      status = 0;
    }

    let updatedReview = {
      ...review,
      site: data.source,
      name: data.name,
      reviewUrl: data.url,
      date: data.datetime,
      content: content,
      starRating: data.rating.toString(),
      status: status,
      avatar: data.featuredMedia,
    };

    if (reviewId) {
      update.mutate(updatedReview,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            handleReset(data, status);
            // navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        }
      );
    } else {
      add.mutate(updatedReview,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            handleReset(data, status);
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        }
      );
    }

    setReview(updatedReview);
  };

  const handleReset = (data, status) => {
    reset({
      source: data.source,
      name: data.name,
      content: content,
      url: data.url,
      datetime: date.format(new Date(data.datetime), "YYYY-MM-DD"),
      rating: data.rating,
      status: status === 0 ? true : false,
      avatar: data.featuredMedia,
    });
  }

  useEffect(() => {
    register("content");
    register("featuredMedia");

    if (!reviews.isLoading) {
      if (reviewId) {
        let foundReview = reviews.data.find((r) => r.id == reviewId);
        loadReview(foundReview);
      } else {
        setRating(getValues("rating"));
        setLoading(false);
      }
    }
  }, [reviews.isLoading, reviews.data, media.isLoading, reviewId, loadReview, register]);


  const pages = [
    { to: "..", label: "Reviews", current: false },
    {
      to: reviewId && review ? `${reviewId}` : "",
      label:
        reviewId && review
          ? `${review.name}'s ${review.site} Review`
          : "New Review",
      current: true,
    },
  ];

  const featuredMediaValue = watch("featuredMedia");

  if (loading || media.isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-20 flex justify-center">
          <LoadingWheel width="w-20" height="h-20" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ReplyToReviewModal agencyId={agency.id} handleSubmit={replyToReview} open={openReply} setOpen={setOpenReply} reviewText={content} />
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          open={featuredMediaOpen}
          setOpen={setFeaturedMediaOpen}
          onInsert={handleFeaturedMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="flex items-center justify-between">
            <div className="sm:flex-auto my-4">
              <h3 className="text-xl font-semibold text-gray-900">
                {reviewId
                  ? `${review.name}'s ${review.site} Review`
                  : "New Review"}
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                {reviewId
                  ? `View and edit ${review.name}'s ${review.site} Review`
                  : "Create a new review using the form below"}
              </p>
            </div>

            <div>
              {review?.reviewUrl && review.reviewUrl?.length > 0 && (
                <a
                  href={review.reviewUrl}
                  target="_blank"
                  className="px-3 py-2 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                >
                  View Original Source
                </a>
              )}
            </div>

          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Review Source"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Source
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        {...register("source")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Manual"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Full Name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Author
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("name")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="John Doe"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="star rating"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Star Rating: {rating}
                    </label>
                    <div className="mt-1 pt-3">
                      <input
                        type="range"
                        {...register("rating")}
                        className="focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => handleRating(e)}
                        min={0} max={5} step={0.5}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Review
                    </label>
                    <div className="mt-1">
                      <input
                        type="date"
                        {...register("datetime")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:mx-2">
                    <label
                      htmlFor="content"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Review Content
                    </label>
                    <TinyMCE
                      value={content}
                      onChange={setContent}
                      height={300}
                    />
                  </div>
                </div>


                {reviewId && review.reply ? (
                  <Card bodyClassName="p-6" title={"Your Reply:"}>
                    {/* <h1 className="font-semibold">Your Reply:</h1> */}
                    <p className="">{review.reply}</p>
                  </Card>
                ) : (
                  <div className="flex justify-end">
                    {reviewId && review.site.toLowerCase() === "google" && <ButtonInput label={"Reply To Review"} isSubmit={false} onClick={() => setOpenReply(true)} />}
                  </div>
                )}

              </div>


              {/* Second Column */}
              <div className="mt-7">

                {reviewId && review.site != "Manual" && <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Review Avatar
                  </label>
                  <div className="border h-60">
                    {featuredMediaValue && !media.isLoading && media.data ? (
                      <img
                        src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                        className="h-full w-full object-contain"
                      />
                    ) : (
                      <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaOpen(true)}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    {!featuredMediaValue ? "Choose" : "Change"}
                  </button>
                </div>}

                <div className={`${reviewId && review.site != "Manual" ? "my-[3.75rem]" : "my-[0.75rem]"} mx-auto`}>
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveBlogEntry px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishBlogEntry px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div >
      </>
    );
  }
}
