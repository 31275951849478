import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEmails(subject = "", sent = true) {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, del } = useApiHelper();

  const emails = useQuery(
    ["emails", agency.id],
    () => get(`/Email/${agency.id}?subject=${subject}&sent=${sent}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const remove = useMutation(
    (emailId) => del(`/Email/${agency.id}/${emailId}`),
    {
      onSuccess: (_, emailId) => {
        queryClient.setQueryData(["emails", agency.id], (currentEmails) =>
          currentEmails.filter((email) => email.id !== emailId)
        );
      },
    }
  );

  return {
    emails,
    remove
  };
}
