import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useNotificationPolicies(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const policies = useQuery(
    ["policies", agencyId],
    () => get(`/notificationpolicy/agency/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (policy) => post(`/notificationpolicy/${policy.branchId}`, policy),
    {
      onSuccess: (addedPolicy) => {
        queryClient.setQueryData(["policies", agencyId], (currentPolicies) => [
          ...currentPolicies,
          addedPolicy.data,
        ]);
      },
    }
  );

  const addAll = useMutation(
    (policy) => post(`/notificationpolicy/agency/${agencyId}/all`, policy),
    {
      onSuccess: (addedPolicies) => {
        queryClient.setQueryData(["policies", agencyId], (currentPolicies) => [
          ...currentPolicies,
          ...addedPolicies.data,
        ]);
      },
    }
  );

  const update = useMutation(
    (policy) => put(`/notificationpolicy/${policy.id}`, policy),
    {
      onSuccess: (updatedPolicy) => {
        queryClient.setQueryData(["policies", agencyId], (currentPolicies) =>
          currentPolicies.map((policy) =>
            policy.id === updatedPolicy.data.id ? updatedPolicy.data : policy
          )
        );
      },
    }
  );

  const remove = useMutation((policyId) => del(`/notificationpolicy/${policyId}`), {
    onSuccess: (_, policyId) => {
      queryClient.setQueryData(["policies", agencyId], (currentPolicies) =>
      currentPolicies.filter((policy) => policy.id !== policyId)
      );
    },
  });

  return {
    policies,
    add,
    addAll,
    update,
    remove,
  };
}
