import React, { useEffect, useState } from 'react';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const [condensedPageNumbers, setCondensedPageNumbers] = useState([]);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const sliceStartPages = (pageNumbers, maxDisplayPages) => {
    const halfMaxDisplayPages = Math.floor(maxDisplayPages / 2);

    if (currentPage <= halfMaxDisplayPages) {
      return pageNumbers.slice(0, maxDisplayPages);
    }

    if (currentPage >= pageNumbers.length - (maxDisplayPages + 1)) {
      return pageNumbers.slice(pageNumbers.length - (maxDisplayPages * 2), pageNumbers.length - maxDisplayPages);
    }

    return pageNumbers.slice(currentPage - (halfMaxDisplayPages + 1), currentPage + halfMaxDisplayPages);
  };

  useEffect(() => {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const totalPages = pageNumbers.length;
    const maxDisplayPages = 5;

    let startPages = sliceStartPages(pageNumbers, maxDisplayPages);
    let endPages = pageNumbers.slice(totalPages - maxDisplayPages, totalPages);

    if (startPages.some(page => endPages.includes(page))) {
      startPages = pageNumbers.slice(0, Math.ceil(totalPages / 2));
      endPages = pageNumbers.slice(Math.ceil(totalPages / 2), totalPages);
    }

    if (currentPage > Math.ceil(maxDisplayPages / 2)) startPages.unshift("...");

    let numbers = [];

    if (totalPages > 2 * maxDisplayPages) {
      if (currentPage < totalPages - (maxDisplayPages + Math.floor(maxDisplayPages / 2))) {

        numbers = startPages.concat(["..."], endPages);

      } else numbers = startPages.concat(endPages);

    } else numbers = pageNumbers;

    setCondensedPageNumbers(numbers);
  }, [nPages, currentPage]);


  return (
    <nav className="py-4 bg-white">
      <ul className="mx-auto flex justify-center text-etpink-600 border-collapse">
        <li className="border border-gray-400 border-r-0 rounded-l-lg">
          <button className="p-2" onClick={prevPage}>
            Previous
          </button>
        </li>
        {condensedPageNumbers.map((pgNumber, index) => (
          <li
            key={index}
            className={`border border-gray-400 border-r-0 flex items-center ${currentPage == pgNumber ? 'bg-etpink-500 text-white' : ''
              } `}
          >
            {pgNumber === "..." ? (
              <span className="pt-1 pb-3 px-4 select-none">...</span>
            ) : (
              <button onClick={() => setCurrentPage(pgNumber)} className="p-2 px-4">
                {pgNumber}
              </button>
            )}
          </li>
        ))}
        <li className="border border-gray-400 rounded-r-lg">
          <button className="p-2" onClick={nextPage}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;






















// import React, { useEffect } from 'react'

// const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

//   const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

//   const nextPage = () => {
//     if (currentPage !== nPages) setCurrentPage(currentPage + 1)
//   }

//   const prevPage = () => {
//     if (currentPage !== 1) setCurrentPage(currentPage - 1)
//   }

//   useEffect(() => {

//   }, [nPages, currentPage])

//   return (
//     <nav className="py-2 bg-white">
//       <ul className="mx-auto flex justify-center text-blue-500 border-collapse">
//         <li className="border border-gray-400 border-r-0 rounded-l-lg">
//           <button className="p-2 "
//             onClick={prevPage}
//           >

//             Previous
//           </button>
//         </li>
//         {pageNumbers.splice(5, pageNumbers.length - 10).map(pgNumber => (
//           <li key={pgNumber}
//             className={`border border-gray-400 border-r-0 ${currentPage == pgNumber ? 'bg-blue-500 text-white' : ''} `} >
//             <button onClick={() => setCurrentPage(pgNumber)}
//               className='p-2 px-4 '
//             >
//               {pgNumber}
//             </button>
//           </li>
//         ))}
//         <li className="border border-gray-400 rounded-r-lg">
//           <button className="p-2 "
//             onClick={nextPage}
//           >

//             Next
//           </button>
//         </li>
//       </ul>
//     </nav>
//   )
// }

// export default Pagination