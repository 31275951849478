import { useEffect, useState } from "react";

import { set, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import useApiHelper from "../../services/useApiHelper";
import useUserContext from "../../contexts/UserContext";
import LoadingWheel from "../Shared/LoadingWheel";

export default function UnsplashMedia({ value, setValue }) {
  const { user: { agency }, } = useUserContext();
  const { post, formData } = useApiHelper();
  const queryClient = useQueryClient();
  const [images, setImages] = useState(undefined)
  const [selectedImage, setSelectedImage] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1)

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      keywords: ""
    },
  });


  const searchImage = (searchQuery, _page) => {
    const searchData = {
      searchQuery: searchQuery,
      resultsPerPage: 12,
      page: _page
    }
    post(`/unsplashgallery/search`, searchData).then(response => {
      if (Object.hasOwn(response, 'data') && response.data.results.length > 0) {
        const { results } = response.data;
        setSelectedImage(undefined);
        setImages(results)
        setIsLoading(false);
      } else {
        // onError("We couldn't find any images for these keywords. Please try different keywords.")
      }
    }).catch(error => {
      // onError(`There was an error fetching images. Please try again. Error: ${error.message}`)
    })

    setUploaded(false);
  }

  const onSubmit = (data) => {
    searchImage(data.keywords, page)
  };

  const insertImage = (shouldInsert = false) => {
    if (selectedImage) {
      setIsLoading(true);
      uploadImage(selectedImage)
        .then(image => {
          if (shouldInsert) setValue(image);
          setIsLoading(false);
          setUploaded(true);
        })
    }
  }

  const uploadImage = (image) => {
    return new Promise((resolve, reject) => {
      //Fetch the url with the width of 1920px
      fetch(image.urls.raw + "&w=1920").then(response => response.blob()).then(blob => {

        const url = agency ? `/media/agency/${agency.id}` : "/media";

        let filename = `${image.user.name.trim()}${image.description ? "-" + image.description.trim() : ""}.jpg`;
        //Remove all non-ascci characters else WP complains
        filename = filename.replace(/[^\x00-\x7F]/g, "");

        post(url, {
          name: image.description ?? "",
          filename: filename,
          type: blob.type,
          size: blob.size,
          altText: image.alt_description.trim(),
          attributionText: attributionText(image).__html,
          dateDeleted: null,
        })
          .then((res) => {
            let fd = new FormData();
            fd.append("media", blob, res.data.filename);
            formData(`/media/${res.data.id}`, fd).then((res2) => {
              // trigger Unsplash download event here
              // there's no need to wait for a response
              post('/unsplashgallery/triggerDownload', image.id)
              queryClient.invalidateQueries("media");
              resolve(res.data);
            });
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      })
    });
  };

  const attributionText = (image) => {
    return {
      __html:
        `<a href="https://unsplash.com/@${image.user.username}?utm_source=EstateTrack&utm_medium=referral" title="Photo by ${image.user.name} on Unsplash" target="_blank">${image.user.name}</a>`
    }
  }

  const changePage = (page) => {
    setImages(undefined)
    setSelectedImage(undefined)
    setIsLoading(true)
    setPage(page)
    searchImage(query, page)
  }

  useEffect(() => {
    return () => {
      setImages(undefined)
      setSelectedImage(undefined)
    }
  }, [])

  if (isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="my-6">
        <div>
          {uploaded ? (
            <>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Uploaded, you can now insert or add a new image to your media library!</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Click insert or search again.</p>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Search unsplash for free images to use</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Enter a few keywords below and try a search.</p>
              </div>
            </>
          )}

          <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full sm:max-w-xs">
              <label htmlFor="keywords" className="sr-only">
                Keywords
              </label>
              <input
                type="text"
                {...register("keywords", { required: true })}
                value={query}
                onChange={(e) => { setQuery(e.target.value); setPage(1); }}
                id="photo-search-keywords"
                className="block sm:text-sm w-full rounded-md py-1.5 text-gray-900 shadow-sm focus:ring-etpink-400 focus:border-etpink-400 border-gray-300 sm:leading-6"
                placeholder="modern house"
              />
            </div>
            <button
              type="button"
              onClick={() => onSubmit(getValues())}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
              Search
            </button>
          </form>
        </div>
      </div>

      {(images && !uploaded) &&
        <>
          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 items-center">
            {images.map(image => (
              <li key={image.id} className="relative">
                <div className={`relative group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 border-etpink-600 ${selectedImage && image.id === selectedImage.id ? 'border-4' : ''}`}>
                  <img
                    src={image.urls.small}
                    alt={image.alt_description}
                    className="cursor-pointer object-cover group-hover:opacity-75"
                    title="Click to select this image"
                    onClick={() => setSelectedImage(image)}
                  />
                  <span className="text-xs px-4 py-1 absolute bottom-0 left-0 text-white w-full bg-black/50" dangerouslySetInnerHTML={attributionText(image)}>
                  </span>
                </div>
              </li>
            ))}
          </ul>

          <div className="text-center mt-6 gap-5">
            <button
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={() => changePage(page - 1)}
            >
              &lt;
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
            >
              {page}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={() => changePage(page + 1)}
            >
              &gt;
            </button>
          </div>

          <div className="text-center my-3">
            <button
              type="button"
              disabled={!selectedImage}
              className="mr-3 mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={() => { insertImage(false); }}
            >
              Add to media library
            </button>

            or

            <button
              type="button"
              disabled={!selectedImage}
              className="ml-3 mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={() => { insertImage(true); }}
            >
              Insert
            </button>
          </div>

        </>
      }
    </>
  );
}
