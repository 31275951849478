import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import AgencyAddModal from "../../components/Agency/AgencyAddModal";

import useAgencies from "../../data/useAgencies";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Tooltip from "../../components/Shared/Tooltips";

import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ActionButton from "../../components/Shared/ActionButton";

function AgencyRow({ agency, onDeleteClick }) {
  const navigate = useNavigate();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(agency.id);
  }

  const handleShadowClick = (e) => {
    e.stopPropagation();
    navigate(`/${agency.logicalName}`);
  }

  return (
    <tr onClick={() => navigate(`/admin/agencies/${agency.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {agency.name}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          <ActionButton onClick={handleShadowClick} tooltip="View" Icon={EyeIcon} />
          <ActionButton onClick={() => {}} tooltip="Edit" Icon={PencilAltIcon} />
          <ActionButton onClick={handleDeleteClick} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function Agencies() {
  const navigate = useNavigate();
  const { agencies: allAgencies, remove } = useAgencies();
  const { deleteHandlers } = useDefaultCRUDHandlers("Agency");
  const [agencies, setAgencies] = useState(allAgencies);
  const [addOpen, setAddOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const deleteAgency = (agencyId) => {
    setDeleteId(agencyId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Admin | Agencies";
    if (!allAgencies.isLoading) {

      //Hide these two agencies
      let filter = allAgencies.data.filter((agency) =>
        !agency.name.toLowerCase().includes("content engine") &&
        !agency.name.toLowerCase().includes("thumbnails")
      );

      filter = filter.filter((agency) =>
        agency.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agency.id.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setAgencies(filter);

    }
  }, [allAgencies.isLoading, allAgencies.data, searchTerm]);

  return (
    <>
      <AgencyAddModal
        open={addOpen}
        setOpen={setAddOpen}
      />
      <ConfirmDelete
        itemName="Agency"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Agencies</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all agencies. You can manage all agencies from here.
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  autoFocus
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Agency Name"
                />
              </div>
              {/* <button
                type="button"
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button>  */}
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => setAddOpen(true)}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Agency
            </button>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {agencies.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-10" height="w-10" />
            </div>
          ) : agencies.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {agencies.map((agency) => (
                  <AgencyRow
                    key={agency.id}
                    agency={agency}
                    onDeleteClick={deleteAgency}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              <NoItems
                itemName="agency"
                plural="Agencies"
                onNewClick={() => navigate("/admin/agency")}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
