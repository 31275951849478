import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  DocumentTextIcon,
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
  FilterIcon,
  ArchiveIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";

import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useEnquiries from "../../data/useEnquiries";
import useUserContext from "../../contexts/UserContext";
import useAgencyBranches from "../../data/useAgencyBranches";
import BranchListBox from "../../components/Shared/BranchListBox";
import ArchivedListBox from "../../components/Shared/ArchivedListBox";
import Avatar from "../../components/Shared/Avatar";
import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ActionButton from "../../components/Shared/ActionButton";
import Pagination from "../../components/Shared/Pagination";

function EnquiryRow({ branches, enquiry, onDeleteClick, archiving, addToArchive }) {
  const { user: { agency } } = useUserContext();
  const navigate = useNavigate();
  const [displayBranch, setDisplayBranch] = useState(null);
  const { undoArchive } = useEnquiries();

  useEffect(() => {
    if (!branches.isLoading) {
      if (enquiry.branchId) {
        var foundBranch = branches.data.find((b) => b.id === enquiry.branchId);
        if (foundBranch)
          setDisplayBranch(foundBranch.name);
      } else {
        setDisplayBranch("None")
      }

    }
  }, [branches.isLoading, branches.data])

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(enquiry.id);
  };

  const handleUndoArchive = (e) => {
    e.stopPropagation();
    undoArchive.mutate([enquiry.id]);
  }

  return (
    <tr
      onClick={() => navigate(`enquiry/${enquiry.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell"
        onClick={(e) => {
          if (archiving) e.stopPropagation();
        }}>
        {archiving && (
          <input 
            type="checkbox" 
            className="h-4 w-4 text-etpink-600 focus:ring-etpink-500 
            border-gray-300 rounded"
            onChange={(e) => {
              addToArchive(enquiry.id, e.target.checked);
            }}
          />
        
        )}

        {enquiry.userId &&
          <Avatar classes="w-10 h-10" userId={enquiry.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {enquiry.fullName}
        <dl className="font-normal sm:hidden">
          <dt className="sr-only sm:hidden">Interested In</dt>
          <dd className="mt-1 truncate text-gray-500">{enquiry.iAmIntrestedIn}</dd>
          <dt className="sr-only">Date Created</dt>
          <dd className="mt-1 truncate text-gray-500">{date.format(new Date(enquiry.created), "DD/MM/YYYY HH:mm")}</dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {enquiry.emailAddress}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {enquiry.telephoneNumber}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {displayBranch}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {agency && enquiry.sourceUrl?.replace(agency?.domain, "").replace("/landing/", "")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {enquiry.iAmIntrestedIn}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(enquiry.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {enquiry.followUpDate ? date.format(new Date(enquiry.followUpDate), "DD/MM/YYYY") : "None"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          {enquiry.archivedOn !== null && (
            <ActionButton onClick={handleUndoArchive} tooltip={"Undo Archive"} Icon={ArchiveIcon} />
          )}
          <ActionButton onClick={() => navigate(`enquiry/${enquiry.id}`)} tooltip="View" Icon={EyeIcon} />
          <ActionButton onClick={handleDeleteClick} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function Enquiries() {
  const { enquiries: allEnquiries, remove, archive, archivedEnquiries } = useEnquiries();
  const { user: { agency }, } = useUserContext();
  const { branches } = useAgencyBranches(agency.id);
  const { deleteHandlers } = useDefaultCRUDHandlers("Enquiry");
  const [showArchived, setShowArchived] = useState(false);
  const [branchFilter, setBranch] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [enquiries, setEnquiries] = useState(allEnquiries);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [archiving, setArchiving] = useState(false);
  const [toArchive, setToArchive] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [nPages, setNPages] = useState(0);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const deleteEnquiry = (enquiryId) => {
    setDeleteId(enquiryId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const toggleArchiving = (save = false) => {
    setArchiving(!archiving);
    if (!save) return;

    archive.mutate(toArchive, {
      onSuccess: () => {
        setToArchive([]);
      }
    });
  }

  const addToArchive = (id, checked) => {
    if (checked) {
      setToArchive([...toArchive, id]);
    } else {
      setToArchive(toArchive.filter((val) => val !== id));
    }
  }

  useEffect(() => {
    document.title = "EstateTrack Portal | Enquiries";

    if (!allEnquiries.isLoading) {
      let listToFilter = showArchived ? archivedEnquiries : allEnquiries;

      var filter = listToFilter.data.filter((enquiry) =>
        enquiry.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enquiry.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enquiry.iAmIntrestedIn?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (branchFilter) {
        var foundBranch = branches.data.find((b) => b.name == branchFilter);
        // if (foundBranch) {
          filter = filter.filter((val) => {
            if (val.branchId) {
              return val.branchId.includes(foundBranch.id)
            }
          });
        // }
      }

      setNPages(Math.ceil(filter.length / recordsPerPage))

      filter = filter.slice(indexOfFirstRecord, indexOfLastRecord);

      setEnquiries(filter);
    }
  }, [
    allEnquiries.isLoading, 
    allEnquiries.data, 
    searchTerm, 
    branchFilter, 
    currentPage,
    showArchived,
  ]);

  return (
    <>
      <ConfirmDelete
        itemName="enquiry"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Enquiries</h1>
            <p className="mt-2 text-sm text-gray-700">A list of all enquiries</p>
          </div>

          <div className="flex mt-2 px-2">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FilterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
              <span>Filter</span>
            </button>
          </div>

          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name, Email or Interest"
                />
              </div>

              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                {archiving && (
                  <button
                    type="button"
                    onClick={() => toggleArchiving(false)}
                    className="inline-flex items-center justify-center rounded-md border 
                    border-transparent bg-gray-400 px-4 py-2 text-sm font-medium 
                    text-white shadow-sm hover:bg-gray-500 focus:outline-none 
                    focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-4"
                  >
                    Cancel
                  </button>
                )}

                <button
                  type="button"
                  onClick={() => toggleArchiving(archiving)}
                  className="inline-flex items-center justify-center rounded-md border 
                  border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium 
                  text-white shadow-sm hover:bg-etpink-700 focus:outline-none 
                  focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto mr-4"
                >
                  {archiving ? "Save" : "Archive"}
                </button>

              </div>

              {/* <button
                type="button"
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button> */}
            </div>
          </div>
        </div>

        {/* Filters hidden */}
        <div id="filters-section" className="pt-2 transition-all ease-in-out delay-250 duration-3000">
          {filterOpen && (
            <div className="grid grid-cols-5 gap-5">
              <BranchListBox setBranch={setBranch} />
              <ArchivedListBox setArchived={setShowArchived} />
            </div>
          )}
        </div>

        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {enquiries.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-10" height="w-10" />
            </div>
          ) : enquiries.length > 0 ? (
            <>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {/* Avatar */}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Full Name
                    </th>
                    <th
                      scope="col"
                      className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Email Address
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Telephone Number
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Branch
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Source URL
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Interested In
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Date Created
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Follow Up
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {enquiries.map((enquiry) => (
                    <EnquiryRow
                      branches={branches}
                      key={enquiry.id}
                      enquiry={enquiry}
                      onDeleteClick={deleteEnquiry}
                      archiving={archiving}
                      addToArchive={addToArchive}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                nPages={nPages}
              />
            </>
          ) : (
            <div className="bg-white p-10">
              <div className="text-center text-sm text-gray-400">
                No Enquiries
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
