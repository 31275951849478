import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DocumentTextIcon,
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";

import useChangelogs from "../../../data/useChangelogs";
import useUserContext from "../../../contexts/UserContext";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import ConfirmDelete from "../../../components/Shared/ConfirmDelete";
import LoadingWheel from "../../../components/Shared/LoadingWheel";
import NoItems from "../../../components/Shared/NoItems";
import formatDateTime from "../../../utils/formatDateTime";
import { DateTime } from "luxon";
import { ButtonInput } from "../../../components/Shared/Inputs/ButtonInput";
import ChangelogEmails from "../../../components/Changelog/ChangelogEmails";
import ActionButtonContainer from "../../../components/Shared/ActionButtonContainer";
import ActionButton from "../../../components/Shared/ActionButton";


function ChangelogRow({ changelog, onDeleteClick }) {
  const navigate = useNavigate();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(changelog.id);
  };


  return (
    <tr
      onClick={() => navigate(`changelog/${changelog.id}`)}
      className="h-[68px] hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
        <span dangerouslySetInnerHTML={{ __html: changelog.title }}></span>

        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Status</dt>
          <dd className="mt-1 truncate">
            <span
              className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${changelog.publishedOn
                ? ("text-green-600 bg-green-100")
                : ("text-yellow-600 bg-yellow-100")
                }`}
            >
              {changelog.publishedOn ? "Published" : "Draft"}
            </span>
          </dd>
          <dt className="sr-only">Version</dt>
          <dd className="mt-1 truncate">{changelog.version}</dd>
        </dl>
      </td>

      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {DateTime.fromISO(changelog.publishedOn).monthLong}
      </td>

      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {formatDateTime(changelog.publishedOn, DateTime.DATE_SHORT)}
      </td>

      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        <span
          className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${changelog.publishedOn
            ? changelog.emailedOn ? ("text-gray-600 bg-gray-100") : ("text-green-600 bg-green-100")
            : ("text-yellow-600 bg-yellow-100")
            }`}
        >
          {changelog.publishedOn 
          ? changelog.emailedOn ? "Sent" : "Published" 
          : "Draft"}
        </span>
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          <ActionButton onClick={() => navigate(`changelog/${changelog.id}`)} tooltip="Edit" Icon={PencilAltIcon} />
          <ActionButton onClick={handleDeleteClick} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function Changelogs() {
  const navigate = useNavigate();
  const { user: { agency } } = useUserContext();
  const { changelogs: allChangelogs, remove } = useChangelogs();
  const { deleteHandlers } = useDefaultCRUDHandlers("Changelog");
  const [changelogs, setChangelogs] = useState(allChangelogs);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deletePost = (post) => {
    setDeleteId(post);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Admin | Changelogs";

    if (!allChangelogs.isLoading) {
      setChangelogs(
        allChangelogs.data.filter((post) =>
          post.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post.version?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allChangelogs.isLoading, allChangelogs.data, searchTerm]);

  return (
    <>
      <ConfirmDelete
        itemName="Blog Post"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <ChangelogEmails open={emailModalOpen} setOpen={setEmailModalOpen} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Changelogs</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all changelogs, you can add and edit changelogs here, this data will go out on the public API and be available to all users.
            </p>
          </div>

          <div className="mt-2 flex rounded-md">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="name"
                id="name"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                placeholder="Changelog title or version"
              />
            </div>
          </div>


          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <ButtonInput label="Manage Email" isSubmit={false} onClick={() => setEmailModalOpen(true)} />
            <Link
              to="changelog"
              type="button"
              className="inline-flex mt-2 items-center justify-center rounded-md border border-transparent bg-etpink-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Changelog
            </Link>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {changelogs.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : changelogs.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Month
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Published On
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {changelogs.map((log) => (
                  <ChangelogRow
                    key={log.id}
                    changelog={log}
                    onDeleteClick={deletePost}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="changelog"
                  onNewClick={() => navigate(`changelog`)}
                />
              ) : (
                <NoItems searching itemName="changelog" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
