import { useNavigate, Link } from "react-router-dom";
import {
  PencilAltIcon,
  TrashIcon,
  ClipboardListIcon,
  CheckCircleIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import date from "date-and-time";
import { SortAscendingIcon } from "@heroicons/react/solid";
import NoItems from "../../components/Shared/NoItems";
import useReviews from "../../data/useReviews";
import { useEffect, useState } from "react";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import { postStatusEnum } from "../../constants/enums";
import useVideoReviews from "../../data/useVideoReviews";
import useAgencyMedia from "../../data/useAgencyMedia";

import { CDN_URL } from "../../services/config";
import useAllMedia from "../../data/useAllMedia";
import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ActionButton from "../../components/Shared/ActionButton";

function ReviewImportStatus() {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Review import successfully completed on 30/05/2022 at 12:15 PM
          </p>
        </div>
      </div>
    </div>
  );
}

function ReviewTableRow({ review, deleteReview }) {
  const navigate = useNavigate();
  const { media } = useAllMedia();
  const [thumbnail, setThumbnail] = useState(null);

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteReview(review.id);
  };

  useEffect(() => {
    if (!media.isLoading) {
      setThumbnail(media.data.find((m) => m.id === review.thumbnailId));

    }
  }, [media.data, media.isLoading])

  return (
    <tr
      onClick={() => navigate(`videoreview/${review.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        <span className="hidden sm:inline">
          {thumbnail && <img alt="Video Thumbnail" className="w-20 h-20 object-contain"
            src={`${CDN_URL}` + `${thumbnail.path}`}
          />
          }
        </span>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Title</dt>
          <dd className="mt-1">{review.title}</dd>
          <dt className="sr-only">Date</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {date.format(new Date(review.date), "DD/MM/YYYY HH:mm")}
          </dd>
          <dt className="sr-only">Status</dt>
          <dd className="mt-1 truncate">
            <span
              className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${review.status === 3
                ? "text-yellow-600 bg-yellow-100"
                : "text-green-600 bg-green-100"
                }`}
            >
              {postStatusEnum[review.status]}
            </span>
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {review.title}
      </td>
      <td className="hidden px-3 py-4 whitespace-nowrap text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(review.date), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        <span
          className={`rounded-full px-5 py-1 font-bold ${review.status === 3
            ? "text-yellow-600 bg-yellow-100"
            : "text-green-600 bg-green-100"
            }`}
        >
          {postStatusEnum[review.status]}
        </span>
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          <ActionButton onClick={() => navigate(`videoreview/${review.id}`)} tooltip="Edit" Icon={PencilAltIcon} />
          <ActionButton onClick={(e) => handleDelete(e)} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function VideoReview() {
  const { videoReviews: allVideoReviews, remove } = useVideoReviews();
  const { deleteHandlers } = useDefaultCRUDHandlers("Video review");
  const [reviews, setReviews] = useState(allVideoReviews);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [sorted, setSorted] = useState(false);

  const navigate = useNavigate();

  const deleteReview = (reviewId) => {
    setDeleteId(reviewId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Video Reviews";

    if (!allVideoReviews.isLoading) {
      setReviews(
        allVideoReviews.data.filter((review) =>
          review.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allVideoReviews.isLoading, allVideoReviews.data, searchTerm, sorted]);

  return (
    <>
      <ConfirmDelete
        itemName="Video Review"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Video Reviews
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage your video reviews here.
            </p>
          </div>
          <div>
            <div className="mt-1 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Review Title"
                />
              </div>
              {/* <button
                type="button"
                onClick={() => setSorted((sorted) => !sorted)}
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button> */}
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="videoreview"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Video Review
            </Link>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {reviews.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : reviews.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className=" hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Thumbnail
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-6"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {reviews.map((review) => (
                  <ReviewTableRow
                    key={review.id}
                    review={review}
                    deleteReview={deleteReview}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="video review"
                  onNewClick={() => navigate("videoreview")}
                />
              ) : (
                <NoItems searching itemName="video review" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
