import Tooltip from "../../components/Shared/Tooltips";


export default function ActionTextButton({ onClick, text }) {

    return (
        <button
            onClick={onClick}
            type="button"
            className="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
        >
            <span>{text}</span>
        </button>
    )
}
