import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useBranchMappings(integrationId, agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const branchMappings = useQuery(
    ["branchMappings", integrationId, agencyId],
    () => get(`/integration/branchmapping/${integrationId}/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (body) => post(`/integration/branchmapping/${integrationId}/${agencyId}`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["branchMappings", integrationId, agencyId]);
      },
    }
  );

  const remove = useMutation(
    (mappingId) => del(`/integration/branchmapping/${mappingId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["branchMappings", integrationId, agencyId]);
      },
    }
  );


  return {
    branchMappings,
    add,
    remove
  };
}
