import { Fragment, useEffect, useState } from "react";

import date from "date-and-time";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { AtSymbolIcon, CalendarIcon, CheckIcon, CloudIcon, HomeIcon, LocationMarkerIcon, PencilIcon, PhoneIcon, SelectorIcon, StarIcon, UserIcon, UsersIcon } from "@heroicons/react/outline";

import Notes from "../../components/Notes/Notes";
import AssignUser from "../../components/Shared/AssignUser";
import Avatar from "../../components/Shared/Avatar";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import Modal from "../../components/Shared/Modal";
import SetFollowUp from "../../components/Shared/SetFollowUp";
import ValuationPictureModal from "../../components/Valuations/ValuationPictureModal";
import ValuationUpdateModal from "../../components/Valuations/ValuationUpdateModal";
import { valuationPropertyTypeEnum, valuationQualityEnum, valuationSourceEnum, valuationStatusEnum, valuationTypeEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useValuations from "../../data/useValuations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import classNames from "../../utils/classNames";
import useNotification from "../../components/notification/useNotifications";
import { TextInput } from "../../components/Shared/Inputs/TextInput";
import { ButtonInput } from './../../components/Shared/Inputs/ButtonInput';

function ValuationCard({
  title,
  TitleComponent,
  children,
  wrapperClasses,
  titleClasses,
}) {
  return (
    <div className={`bg-white divide-y ${wrapperClasses}`}>
      {TitleComponent ? (
        <TitleComponent />
      ) : (
        <div
          className={`prose-lg text-gray-500 font-medium py-3 px-5 ${titleClasses}`}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
}

function ProspectDetails({ valuation }) {
  const { updateProspect } = useValuations();
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { register, handleSubmit, reset } = useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reset(valuation);
  }, [valuation]);

  const onSubmit = (data) => {
    // console.log(data);
    setIsLoading(true);

    const body = {
      ...valuation,
      ...data,
    };

    updateProspect.mutate(body, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onSettled: () => setIsLoading(false),
    });
  };

  return (
    <>
      <Modal open={open} setOpen={setOpen} width={"max-w-3xl"}>
        <h1 className="text-xl">Edit Prospect Details</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 mt-4">
          <TextInput label="Name" register={register} registerName="yourName" />
          <TextInput label="Email Address" register={register} registerName="emailAddress" />
          <TextInput label="Phone Number" register={register} registerName="telephoneNumber" />
          <TextInput label="House Number" register={register} registerName="houseNumber" />
          <TextInput label="Street" register={register} registerName="street" />
          <TextInput label="Town" register={register} registerName="town" />
          <TextInput label="Postcode" register={register} registerName="postcode" />

          <div className="flex justify-end">
            {isLoading ? (
              <div className="pr-12">
                <LoadingWheel width="w-8" height="h-8" />
              </div>
            )
              : <ButtonInput label="Save" isSubmit classes="mt-4" onClick={() => { }} />}
          </div>
        </form>
      </Modal>
      <ValuationCard title="Prospect Details">
        <div className="prose-sm text-gray-700 py-3 px-5">
          <div className="grid grid-cols-12 gap-y-2">
            <div className="col-span-1 flex items-center">
              <UserIcon className="w-4 h-4" />
            </div>
            <div className="col-span-11"><span dangerouslySetInnerHTML={{ __html: `${valuation.yourName}` }}></span></div>
            <div className="col-span-1 flex items-center">
              <AtSymbolIcon className="w-4 h-4" />
            </div>
            <div className="break-words col-span-11">{valuation.emailAddress}</div>
            <div className="col-span-1 flex items-center">
              <PhoneIcon className="w-4 h-4" />
            </div>
            <div className="col-span-11">{valuation.telephoneNumber}</div>
            <div className="col-span-1 flex items-center">
              <LocationMarkerIcon className="w-4 h-4" />
            </div>
            <div className="col-span-11 leading-4">
              {valuation.houseNumber}
              <br />
              {valuation.street}
              <br />
              {valuation.town}
              <br />
              {valuation.postcode}
            </div>
            <div className="col-span-1 flex items-center">
              <CalendarIcon className="w-4 h-4" />
            </div>
            <div className="col-span-11">Looking to Move: {valuation.lookingToMove ? (valuation.lookingToMove == 1 ? `${valuation.lookingToMove} month` : `${valuation.lookingToMove} months`) : ("Just looking")}</div>
          </div>
          {valuation.sourceName === "Manual" && (
            <div className="flex justify-end">
              <PencilIcon className="w-4 h-4 text-gray-700 cursor-pointer mt-3" onClick={() => setOpen(true)} />
            </div>
          )}
        </div>
      </ValuationCard>
    </>
  );
}

function PropertyDetails({ valuation }) {

  return (
    <ValuationCard title="Property Details">
      <div className="prose-sm text-gray-700 py-3 px-5">
        <div className="grid grid-cols-12 gap-y-2">
          <div className="col-span-1 flex items-center">
            <HomeIcon className="w-4 h-4" />
          </div>
          <div className="col-span-11">
            {valuationPropertyTypeEnum[valuation.propertyType]}
          </div>

          {(valuation.initialValuationSource === 1 || valuation.valuationSource !== 2) && (
            <>
              <div className="col-span-1 flex items-center">
                <UsersIcon className="w-4 h-4" />
              </div>
              <div className="col-span-11">{valuation.noBedrooms} Bedrooms</div>
              <div className="col-span-1 flex items-center">
                <CloudIcon className="w-4 h-4" />
              </div>
              <div className="col-span-11">
                {valuation.noBathrooms} Bathrooms
              </div>
              <div className="col-span-1 flex items-center">
                <StarIcon className="w-4 h-4" />
              </div>
              <div className="col-span-11">
                Quality of finish: {valuationQualityEnum[valuation.qualityOfFinish]}
              </div>
            </>
          )}
        </div>
      </div>
    </ValuationCard>
  );
}

function MapView({ valuation }) {
  return (
    <ValuationCard
      title="View on Map"
      wrapperClasses="col-span-1 md:col-span-2 md:row-span-2"
    >
      <div className="py-3 px-5 h-full pb-[15%]">
        <iframe
          className=""
          title="Google map"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen=""
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDipwICcP5ZhQHsgEpZL5ClGpR95mN6G7M&q=${valuation.postcode}`}
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </ValuationCard>
  );
}

function SalesValuation({ valuation }) {
  var priceUpdate = "";

  if (valuation.valuationUpdated === true) {
    priceUpdate = "(Updated Instant Valuation)";
  }
  else if (valuation.valuationSource === 1) {
    priceUpdate = "(Instant Valuation)";
  }

  return (
    <ValuationCard title="Sales Valuation">
      <div className={"prose-2xl font-semibold py-3 px-5 " + (valuation.appraisalSent ? "text-gray-700" : "text-etpink-700")}>
        {valuation.saleValue !== 0
          ? `£
          ${Intl.NumberFormat(undefined, { currency: "GBP" }).format(
            valuation.saleValue
          )}`
          : "£--"}

        <p className="prose-sm text-gray-700 pt-3">
          {valuation.saleValue !== 0 && !valuation.appraisalSent && priceUpdate}
        </p>

      </div>

    </ValuationCard>
  );
}

function RentalValuation({ valuation }) {
  var priceUpdate = "";

  if (valuation.valuationUpdated === true) {
    priceUpdate = "(Updated Instant Valuation)";
  }
  else if (valuation.valuationSource === 1) {
    priceUpdate = "(Instant Valuation)";
  }

  return (
    <ValuationCard title="Rental Valuation">
      <div className={"prose-2xl font-semibold py-3 px-5 " + (valuation.appraisalSent ? "text-gray-700" : "text-etpink-700")}>
        {valuation.rentalValue !== 0
          ? `£${Intl.NumberFormat(undefined, { currency: "GBP" }).format(
            valuation.rentalValue
          )} pcm`
          : "£--"}

        <p className="prose-sm text-gray-700 pt-3">
          {valuation.rentalValue !== 0 && !valuation.appraisalSent && priceUpdate}
        </p>
      </div>
    </ValuationCard>
  );
}

function AppointmentDateTime({ valuation }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { updateDates, notifyClient } = useValuations();
  const [open, setOpen] = useState(false)
  const [appointmentDate, setAppointmentDate] = useState(valuation.appointmentDateTime);

  const handleSaveDate = (notify = false) => {
    if (appointmentDate) {

      let body = {
        ...valuation,
        appointmentDateTime: appointmentDate
      };

      if (notify) {

        notifyClient.mutate(body, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setOpen(false);
          },
          onError: (error) => {
            saveHandlers.onError(error);
          }
        });

      } else {

        updateDates.mutate(body, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setOpen(false);
          },
          onError: (error) => {
            saveHandlers.onError(error);
          }
        });

      }

    }
  }

  return (
    <>
      <Modal width={"max-w-3xl"} open={open} setOpen={setOpen}>
        <h1 className="text-xl">Set a date and time for this valuation's appointment</h1>

        <div className="flex flex-col mt-4">
          <label htmlFor="appointmentDate" className="text-sm font-medium text-gray-700">Appointment Date & Time</label>
          <input
            type="datetime-local"
            name="appointmentDate"
            id="appointmentDate"
            initialValue={appointmentDate}
            onChange={(e) => {
              setAppointmentDate(e.target.value);
            }}
            className="mt-1 focus:ring-etpink-500 focus:border-etpink-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="flex justify-between">
            <div>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="px-3 py-3 mr-3 mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Close
              </button>
              <button type="button" onClick={() => handleSaveDate(false)} className="mt-4 rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto">
                Save
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => { handleSaveDate(true) }}
                className="mt-4 px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Save & Notify Client
              </button>
            </div>

          </div>
        </div>
      </Modal>

      <ValuationCard title="Appointment Date" wrapperClasses={"mt-6"}>
        <div className={"prose-sm text-gray-700 py-3 px-5"}>
          <p>
            {valuation.appointmentDateTime && valuation.appointmentDateTime !== "0001-01-01T00:00:00" ?
              date.format(new Date(valuation.appointmentDateTime), "DD/MM/YYYY HH:mm")
              :
              ("No appointment set")
            }
          </p>
          <div className="flex mt-4">
            <button type="button" onClick={() => setOpen(true)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto">
              Set appointment
            </button>
          </div>
        </div>
      </ValuationCard>
    </>
  );
}

function FollowUpDateTime({ valuation }) {
  const { updateDates } = useValuations();
  const [open, setOpen] = useState(false)

  return (
    <>
      <ValuationCard title="Follow Up Date" wrapperClasses={"mt-6"}>
        <div className={"prose-sm text-gray-700 py-3 px-5"}>
          <p>
            {valuation.followUpDate && valuation.followUpDate !== "0001-01-01T00:00:00" ?
              date.format(new Date(valuation.followUpDate), "DD/MM/YYYY")
              :
              ("No follow up set")
            }
          </p>
          <div className="flex">
            <SetFollowUp open={open} setOpen={setOpen} update={updateDates} lead={valuation} saveHandler={"Valuation"} classes={""} />
          </div>
        </div>
      </ValuationCard>
    </>
  );
}

function ManageUserAssigning({ valuation }) {
  const { update } = useValuations();
  const [open, setOpen] = useState(false)

  return (
    <AssignUser open={open} setOpen={setOpen} lead={valuation} update={update} saveHandler={"Valuation"} classes="mt-5 mr-4" />
  )
}

function NearbyPrices({ title, sold, lastUpdatedNearbySold, nearbyProperties, isRefreshing, onRefreshClick }) {
  const [sortBy, setSortBy] = useState("Sold Date");
  const [nearbySold, setNearbySold] = useState(
    [...nearbyProperties].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )
  );
  const sortOptions = ["Sold Date", "Distance"];

  const handleSort = (v) => {
    setSortBy(v);
    if (v === "Sold Date") {
      setNearbySold((cns) =>
        cns.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    } else {
      setNearbySold(nearbyProperties);
    }
  };

  const Title = () => (
    <div className="py-3 px-5 flex items-center justify-between">
      <div>
        <div className="prose-lg text-gray-500 font-medium">
          {title}
        </div>
        {nearbyProperties.length > 0 && (
          <div className="prose-sm text-gray-400">
            Updated{" "}
            {date.format(
              new Date(lastUpdatedNearbySold),
              "DD/MM/YYYY HH:mm"
            )}
          </div>
        )}
      </div>
      <div className="flex items-end">
        <div className="mr-5 w-48">
          <Listbox value={sortBy} onChange={handleSort}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  Sort by
                </Listbox.Label>
                <div className="mt-1 relative">
                  <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                    <span className="block truncate">
                      {sortOptions.find((o) => o === sortBy)}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    appear={true}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {sortOptions.map((o) => (
                        <Listbox.Option
                          key={o}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-etpink-600"
                                : "text-gray-900",
                              "cursor-default select-none relative py-2 pl-8 pr-4"
                            )
                          }
                          value={o}
                        >
                          <>
                            <span
                              className={classNames(
                                sortBy === o ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {o}
                            </span>

                            {sortBy === o ? (
                              <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <button
          onClick={onRefreshClick}
          type="button"
          className="h-1/2 inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
        >
          Refresh Prices
        </button>
      </div>
    </div>
  );

  return (
    <ValuationCard
      TitleComponent={Title}
      wrapperClasses="col-span-1 md:col-span-4"
    >
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap w-[130px] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Date
            </th>
            <th
              scope="col"
              className="whitespace-nowrap grow-1 px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Address
            </th>
            {sold && (
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Property Type
              </th>
            )}
            <th
              scope="col"
              className="whitespace-nowrap text-right pr-10 py-3.5 text-sm font-semibold text-gray-900"
            >
              Price
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!isRefreshing ? (
            nearbySold.length > 0 ? (
              nearbySold.map((ns) => (
                <tr key={ns.id}>
                  <td className="whitespace-nowrap text-xs py-2 pl-4 pr-3 text-gray-500 sm:pl-6 md:text-sm">
                    {date.format(new Date(ns.date), "DD/MM/YYYY")}
                  </td>
                  <td className="whitespace-nowrap text-xs px-2 py-2 text-gray-900 md:text-sm">
                    {ns.address}
                  </td>
                  {sold && (
                    <td className="whitespace-nowrap text-xs px-2 py-2 text-gray-900 md:text-sm">
                      {ns.type}
                    </td>
                  )}
                  <td className="whitespace-nowrap text-xs text-right pr-8 py-2 text-gray-500 md:text-sm">
                    {`£${Intl.NumberFormat(undefined, {
                      currency: "GBP",
                    }).format(ns.price)}`}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="px-2 py-6 text-gray-400 text-sm text-center">
                    No nearby sales data could be found
                  </div>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="5">
                <div className="py-6 flex justify-center">
                  <LoadingWheel width="w-8" height="w-8" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </ValuationCard>
  );
}

function LiveListings({ lastUpdatedNearbySold, listings, isRefreshing, onRefreshClick }) {
  const [imageUrl, setImageUrl] = useState("");
  const [openPicture, setOpenPicture] = useState(false);
  const [liveListings, setLiveListings] = useState(
    [...listings].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )
  );

  const handleOpenPicture = (url) => {
    setImageUrl(url);
    setOpenPicture(true);
  }

  const Title = () => (
    <div className="py-3 px-5 flex items-center justify-between">
      <div>
        <div className="prose-lg text-gray-500 font-medium">
          Live Listings
        </div>
        {listings.length > 0 && (
          <div className="prose-sm text-gray-400">
            Updated{" "}
            {date.format(
              new Date(lastUpdatedNearbySold),
              "DD/MM/YYYY HH:mm"
            )}
          </div>
        )}
      </div>
      <div className="flex items-end">
        <button
          onClick={onRefreshClick}
          type="button"
          className="h-1/2 inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
        >
          Refresh Prices
        </button>
      </div>
    </div>
  );

  return (
    <>
      <ValuationPictureModal open={openPicture} setOpen={setOpenPicture} imageUrl={imageUrl} />
      <ValuationCard
        TitleComponent={Title}
        wrapperClasses="col-span-1 md:col-span-4"
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap w-[130px] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Image
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Address
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Listing Type
              </th>
              <th
                scope="col"
                className="whitespace-nowrap text-right pr-10 py-3.5 text-sm font-semibold text-gray-900"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!isRefreshing ? (
              liveListings.length > 0 ? (
                liveListings.map((listing) => (
                  <tr key={listing.id}>
                    <td className="whitespace-nowrap text-xs py-2 pl-4 pr-3 text-gray-500 sm:pl-6 md:text-sm">
                      <img src={listing.imageUrl} className="w-16 hover:cursor-pointer" onClick={() => handleOpenPicture(listing.imageUrl)} />
                    </td>
                    <td className="whitespace-nowrap text-xs px-2 py-2 text-gray-900 md:text-sm">
                      {listing.address}
                    </td>
                    <td className="whitespace-nowrap text-xs px-2 py-2 text-gray-900 md:text-sm">
                      {listing.listingType ? "Rent" : "Sale"}
                    </td>
                    <td className="whitespace-nowrap text-right text-xs pr-6 py-2 text-gray-500 md:text-sm">
                      {`£${Intl.NumberFormat(undefined, {
                        currency: "GBP",
                      }).format(listing.price)} ${listing.listingType === 1 ? "PCM" : ""}`}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    <div className="px-2 py-6 text-gray-400 text-sm text-center">
                      No nearby sales data could be found
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="py-6 flex justify-center">
                    <LoadingWheel width="w-8" height="w-8" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ValuationCard>
    </>
  );
}

function StatusDropdown({ valuation }) {
  const { update } = useValuations();
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { register, getValues, setValue, watch, handleSubmit, reset } = useForm(
    { defaultValues: { status: valuation.status } }
  );
  const [statusName, setStatusName] = useState("");

  const statuses = [
    { name: "Contact Made", value: 2 },
    { name: "Booked", value: 3 },
    { name: "Won", value: 5 },
    { name: "Lost", value: 1 },
  ];

  const statusValue = watch("status");

  const updateStatus = (value) => {
    setValue("status", value);
    update.mutate(
      {
        ...valuation,
        status: value,
      },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
        },
        onError: () => {
          saveHandlers.onError();
        },
      }
    );
  };

  useEffect(() => {
    setValue("status", valuation.status);

    var name = statuses.find((s) => s.value === valuation.status)?.name;

    if (name === undefined) {
      name = valuationStatusEnum[valuation.status];
    }

    setStatusName(name);
  }, [valuation.status]);

  return (
    <form>
      <Listbox
        {...register("status")}
        value={statusValue}
        onChange={(v) => updateStatus(v)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Status
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {statusName}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-66 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {statuses.map((s) => (
                    <Listbox.Option
                      key={s.value}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-etpink-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={s.value}
                    >
                      <>
                        <span
                          className={classNames(
                            getValues("status") === s.value
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {s.name}
                        </span>

                        {getValues("status") === s.value ? (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </form>
  );
}

export default function ValuationView() {
  const { user: { agency } } = useUserContext();
  const { valuationId } = useParams();
  const { valuations, update, refreshNearbySold, updateNotes, removeNote, resendEmail } =
    useValuations();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Valuation");
  const [refreshingNearbySold, setRefreshingNearbySold] = useState(false);
  const [valuation, setValuation] = useState(undefined);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [file, setFile] = useState("");
  const [isInstant, setIsInstant] = useState(false);
  const { addNotification } = useNotification();

  useEffect(() => {
    if (!valuations.isLoading) {
      if (valuationId) {
        let valuation = valuations.data.find((v) => v.id === valuationId);
        setValuation(valuation);
        setRefreshingNearbySold(false);
      }
    }
  }, [
    valuations.isLoading,
    valuations.data,
    valuations.isFetching,
    valuationId,
  ]);

  const confirmDelete = (note) => {
    var updateData = {
      noteId: note.id,
      valuationId: valuationId,
    };

    removeNote.mutate(updateData, deleteHandlers);
  };

  const onSubmit = (data) => {
    console.log("submitting", data);
    let mutateData = {
      valuationId: valuationId,
      newNote: {
        note: data.note,
      },
    };
    if (valuationId) {
      updateNotes.mutate(mutateData, saveHandlers);
    }
  };

  const handleOpen = (source) => {
    setIsInstant(source);
    setUpdateOpen(true);
  };

  const handleFileOpen = (file) => {
    setFile(file);
    setOpenFile(true);
  }

  const pages = [
    { to: "..", label: "Valuations", current: false },
    {
      to: valuationId && valuation ? `${valuationId}` : "",
      label:
        valuationId && valuation
          ? `Valuation for ${valuation.yourName.replace("&amp;", "&").replace("&#39;", "'")}`
          : "New Valuation",
      current: true,
    },
  ];

  if (valuations.isLoading || !valuation) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ValuationUpdateModal
          open={updateOpen}
          setOpen={setUpdateOpen}
          valuation={valuation}
          isInstant={isInstant}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="my-6 flex items-center justify-between">
            <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <h1 className="text-xl font-semibold text-gray-900">
                    <span dangerouslySetInnerHTML={{ __html: `Valuation for ${valuation.yourName}` }}></span>
                  </h1>
                  <span
                    className={`ml-3 prose-sm rounded-full px-3 font-bold 
                  ${valuation.status === 0
                        ? "text-yellow-600 bg-yellow-100"
                        : valuation.status === 1
                          ? "text-red-600 bg-red-100"
                          : "text-green-600 bg-green-100"
                      }`}
                  >
                    {valuationStatusEnum[valuation.status]}
                  </span>
                </div>
                <div>
                  <p>
                    {valuationSourceEnum[valuation.valuationSource]} |{" "}
                    {valuationTypeEnum[valuation.valuationType]} |{" "}
                    {date.format(new Date(valuation.created), "DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              </div>
              <Avatar classes="ml-2 w-10 h-10" userId={valuation.userId ?? ""} />
            </div>

            <div className="flex flex-1 justify-end items-center">
              <div className="mr-4 mb-px min-w-[180px]">
                <StatusDropdown valuation={valuation} />
              </div>
              <div>
                <ManageUserAssigning valuation={valuation} />
              </div>
              <div className="mt-5">
                {valuation.valuationSource === 1 ? (
                  <button
                    onClick={() => handleOpen(true)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Update Valuation
                  </button>
                ) : (
                  <>
                    {valuation.appraisalSent &&
                      <>
                        <a
                          href={`${agency.domain}/appraisal?t=${valuationId}`}
                          target="_blank"
                          type="button"
                          className="mr-4 inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                        >
                          View Appraisal Page
                        </a>
                        <button
                          onClick={() => {
                            if (window.confirm("Are you sure you want to resend this appraisal?")) resendEmail.mutate(valuationId, {
                              onSuccess: (response) => {
                                // saveHandlers.onSuccess();
                                if (response.success == false) return;
                                addNotification({
                                  variant: "success",
                                  primaryText: response.message,
                                })
                              },
                              onError: () => {
                                saveHandlers.onError();
                              }
                            });
                          }}
                          type="button"
                          className="mr-4 inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                        >
                          Resend Appraisal
                        </button>
                      </>
                    }
                    <button
                      onClick={() => handleOpen(false)}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                    >
                      {valuation.appraisalSent ? ("Edit Appraisal") : ("Add Appraisal")}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="my-5">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <ProspectDetails valuation={valuation} />
              <PropertyDetails valuation={valuation} />
              <MapView valuation={valuation} />
              <div>
                <SalesValuation valuation={valuation} />
                <AppointmentDateTime valuation={valuation} />
              </div>

              <div>
                <RentalValuation valuation={valuation} />
                <FollowUpDateTime valuation={valuation} />
              </div>

              {valuation.appraisalNotes && <div className="p-4 col-span-1 md:col-span-4 bg-white">
                <dt className="text-sm font-medium text-gray-500">Appraisal Notes</dt>
                <div className="mt-1">
                  {valuation.appraisalNotes}
                </div>
              </div>}
              <ValuationPictureModal open={openFile} setOpen={setOpenFile} imageUrl={file} />
              {valuation.files && <div className="p-4 col-span-1 md:col-span-4 bg-white">
                <dt className="text-sm font-medium text-gray-500">Files</dt>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-6 lg:mt-4">
                  {JSON.parse(valuation.files).map((file, index) =>
                    <div key={index} className="col-span-1 flex justify-center bg-gray-50 p-4">
                      <img
                        onClick={() => handleFileOpen(file)}
                        className="max-h-40 p-2 cursor-pointer object-contain border border-gray-400 rounded-md shadow-md"
                        src={file}
                        alt="Workcation"
                      />
                    </div>
                  )}
                </div>
              </div>}
              <div className="p-4 col-span-1 md:col-span-4 bg-white">
                <Notes
                  notes={valuation.notes}
                  submitFunction={onSubmit}
                  deleteFunction={confirmDelete}
                />
              </div>
              {(valuation.valuationType === 0 || valuation.valuationType === 1) && (
                <NearbyPrices
                  title={"Nearby Sold Prices"}
                  sold={true}
                  lastUpdatedNearbySold={valuation.lastUpdatedNearbySold}
                  nearbyProperties={valuation.nearbySold}
                  isRefreshing={refreshingNearbySold}
                  onRefreshClick={() => {
                    setRefreshingNearbySold(true);
                    refreshNearbySold.mutate(valuation.id);
                  }}
                />
              )}
              {(valuation.valuationType === 0 || valuation.valuationType === 2) && (
                <NearbyPrices
                  title={"Nearby Rental Prices"}
                  sold={false}
                  lastUpdatedNearbySold={valuation.lastUpdatedNearbySold}
                  nearbyProperties={valuation.nearbyRented}
                  isRefreshing={refreshingNearbySold}
                  onRefreshClick={() => {
                    setRefreshingNearbySold(true);
                    refreshNearbySold.mutate(valuation.id);
                  }}
                />
              )}
              <LiveListings
                lastUpdatedNearbySold={valuation.lastUpdatedNearbySold}
                listings={valuation.liveListings}
                isRefreshing={refreshingNearbySold}
                onRefreshClick={() => {
                  setRefreshingNearbySold(true);
                  refreshNearbySold.mutate(valuation.id);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
