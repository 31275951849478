import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useForm, useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import AiPromptModal from "../../components/Blog/AiPromptModal";
import AgenciesList from "../../components/ContentEngine/AgenciesList";
import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import TinyMCE from "../../components/Shared/TinyMCE";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useContentEngineCategories from "../../data/useContentEngineCategories";
import useContentEngineMedia from "../../data/useContentEngineMedia";
import useContentEnginePosts from "../../data/useContentEnginePosts";
import useGroups from "../../data/useGroups";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import classNames from "../../utils/classNames";

export default function ContentEnginePostAddEdit() {
  const queryClient = useQueryClient();
  const { media } = useContentEngineMedia();
  const { postId } = useParams();
  const { contentEnginePosts, add, update } = useContentEnginePosts();
  const { categories } = useContentEngineCategories();
  const { groups } = useGroups();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm({
      defaultValues: {
        category: "",
        group: "",
        featuredMedia: null,
        excerpt: "",
        content: "",
        datePublished: date.format(new Date(), "YYYY-MM-DD HH:mm"),
      },
    });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Blog post");
  const [post, setPost] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [excerpt, setExcerpt] = useState("");
  const [content, setContent] = useState("");
  const [aiPostData, setAiPostData] = useState(null); // [title, excerpt, body]
  const [isDraft, setIsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setup, setSetup] = useState(false);
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [openAiContentModal, setOpenAiContentModal] = useState(state ? Boolean(state.openAiModal) : false);

  const blogPreviewRef = useRef(null);
  const excerptEditorRef = useRef();
  const contentEditorRef = useRef();

  const previewBlogEntry = () => {
    blogPreviewRef.current.setOpen(true);
  };

  const onSubmit = (data) => {
    console.log(aiPostData);
    setIsLoading(true);
    if (postId) {
      update.mutate(
        {
          ...post,
          title: data.title,
          categoryId: data.category,
          groupId: data.group,
          mediaManagerId: data.featuredMedia,
          excerpt: excerpt,
          content: content,
          status: isDraft ? 3 : 0,
          datePublished: new Date(data.datePublished),
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            // navigate(-1);
            navigate(`/admin/content-engine`);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    } else {
      add.mutate(
        {
          ...post,
          title: data.title,
          categoryId: data.category,
          groupId: data.group,
          mediaManagerId: data.featuredMedia,
          excerpt: excerpt,
          content: content,
          publish: !isDraft,
          datePublished: new Date(data.datePublished),
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            navigate(`/admin/content-engine`);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    }

    reset({
      title: data.title,
      category: data.category,
      group: data.group,
      featuredMedia: data.featuredMedia,
      excerpt: data.excerpt,
      content: data.content,
      datePublished: date.format(new Date(data.datePublished), "YYYY-MM-DD HH:mm"),
    });

    setPost({
      ...post,
      title: data.title,
      categoryId: data.category,
      groupId: data.group,
      mediaManagerId: data.featuredMedia,
      excerpt: excerpt,
      content: content,
      status: isDraft ? 3 : 0,
      datePublished: data.datePublished
    });
  };

  const loadPost = useCallback(
    (post) => {
      setPost(post);

      setExcerpt(post.excerpt);
      setContent(post.content);

      //Failsafe
      var category;
      if (post.blogPostCategories.length > 0) {
        category = post.blogPostCategories[0].categoryId;
      } else {
        category = categories.data[0].id;
      }

      reset({
        title: post.title,
        category: category,
        group: post.groupId,
        featuredMedia: post.mediaManagerId,
        excerpt: excerpt,
        content: content,
        datePublished: date.format(new Date(post.datePublished), "YYYY-MM-DD HH:mm"),
      });
    },
    [reset]
  );

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("allMedia");

    setTimeout(() => {
      setValue("featuredMedia", media.id, { shouldDirty: true });
      setFeaturedMediaOpen(false);
    }, 1000);
  };

  const onInsertImage = useCallback((imageId) => {
    setValue("featuredMedia", imageId, { shouldDirty: true })
  }, [setValue])

  useEffect(() => {
    if (!setup) {
      register("excerpt");
      register("content");
      register("featuredMedia");
      if (
        !contentEnginePosts.isLoading &&
        !categories.isLoading &&
        !groups.isLoading &&
        !media.isLoading
      ) {
        if (postId) {
          let post = contentEnginePosts.data.find((bp) => bp.id === postId);
          loadPost(post);
        } else {
          setValue("category", categories.data[0].id);
          setValue("group", groups.data[0].id);
        }

        setSetup(true);
      }
    }
  }, [
    contentEnginePosts.isLoading,
    contentEnginePosts.data,
    categories.isLoading,
    categories.data,
    groups.isLoading,
    groups.data,
    media.isLoading,
    media.data,
    postId,
    loadPost,
    register,
    setValue,
  ]);

  const pages = [
    { to: "..", label: "Content Engine Posts", current: false },
    {
      to: "",
      label: postId ? `${getValues("title")}` : "New Blog Post",
      current: true,
    },
  ];

  const categoryValue = watch("category");
  const groupValue = watch("group");
  const featuredMediaValue = watch("featuredMedia");

  if (
    isLoading ||
    contentEnginePosts.isLoading ||
    categories.isLoading ||
    groups.isLoading ||
    media.isLoading ||
    categoryValue === "" ||
    groupValue === ""
  ) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          isAdmin={true}
          open={featuredMediaOpen}
          setOpen={setFeaturedMediaOpen}
          onInsert={handleFeaturedMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="flex justify-between text-center md:text-left flex-col md:flex-row gap-4 sm:flex-auto my-4">
            <div>
              <h3 className="text-xl font-semibold text-gray-900">
                {postId ? `${getValues("title")}` : "New Blog Post"}
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                {postId
                  ? `View and edit ${getValues("title")}`
                  : "Create a new blog post"}
              </p>
            </div>

            <div className="my-4 text-center">
              <button
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpenAiContentModal(true) }}
              >
                <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  {post ? 'Regenerate' : 'Generate'} content using AI
                </span>
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="titleContainer my-4">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("title")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Blog Title"
                    />
                  </div>
                </div>


                <div className="mt-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("category")}
                      value={categoryValue}
                      onChange={(v) => setValue("category", v.id)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                            Category
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  categories.data.find(
                                    (c) => c.id === categoryValue
                                  ).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {categories.data.map((c) => (
                                  <Listbox.Option
                                    key={c.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={c}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          categoryValue === c.id
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {c.name}
                                      </span>

                                      {categoryValue === c.id ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("group")}
                      value={groupValue}
                      onChange={(v) => setValue("group", v.id)}
                      disabled={postId ? true : false}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                            Group
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className={classNames(postId && "bg-gray-300", "relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm")}>
                              <span className="block truncate">
                                {
                                  groups.data.find((g) => g.id === groupValue)
                                    .name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {groups.data.map((g) => (
                                  <Listbox.Option
                                    key={g.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={g}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          groupValue === g.id
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {g.name}
                                      </span>

                                      {groupValue === g.id ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="flex mt-4 md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label className="mb-2 block text-sm font-medium text-gray-700">
                      Published
                    </label>
                    <input {...register("datePublished")}
                      type="datetime-local"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">

                  </div>
                </div>

                <div className="mt-8">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Excerpt
                  </label>
                  <TinyMCE
                    value={excerpt}
                    onChange={setExcerpt}
                    height={200}
                  />
                </div>

                <div className="my-8">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Content
                    </label>
                  </div>
                  <AiPromptModal
                    postTitle={post ? post.title : ""}
                    blogPost={post}
                    open={openAiContentModal}
                    setOpen={setOpenAiContentModal}
                    onGenerate={
                      (aiContent, postdata) => {
                        setValue('title', aiContent.title, { shouldDirty: true });
                        setExcerpt(aiContent.excerpt)
                        setContent(aiContent.body)
                        setAiPostData(postdata)
                      }
                    }
                    onInsertImage={onInsertImage}
                  />
                  <TinyMCE
                    value={content}
                    onChange={setContent}
                    height={300}
                  />
                </div>
              </div>
              <div className="mt-7">

                <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Featured Image
                  </label>
                  <div className="border h-60">
                    {featuredMediaValue && !media.isLoading && media.data ? (
                      <img
                        src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                        className="h-full w-full object-contain"
                      />
                    ) : (
                      <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaOpen(true)}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    {!featuredMediaValue ? "Choose" : "Change"}
                  </button>
                </div>

                <div className="my-[3.75rem] mx-auto">
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveBlogEntry px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishBlogEntry px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>

              </div>
            </div>
          </form >

          <div className="p-4 mb-5 bg-white shadow-sm rounded-md">
            <AgenciesList postId={postId} groupId={groupValue} />
          </div>

        </div >
      </>
    );
  }
}
