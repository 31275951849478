import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useApexBranches(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const branches = useQuery(
    ["apexBranches", agencyId],
    () => get(`/integration/apex/branches/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  return {
    branches,
  };
}
