import useAgencyServiceToggles from "../../../data/useAgencyServiceToggles"
import classNames from "../../../utils/classNames"

export const AiButtonInput = ({ agencyId, label, isSubmit, onClick, classes, disabled = false, children }) => {
  const { serviceToggles } = useAgencyServiceToggles(agencyId);

  //Don't show until Agency has been verified to have AI usage access
  if (serviceToggles.isLoading || !serviceToggles.data || !serviceToggles.data.aiUsageToggle) return <div></div>;

  return (
    <button
      className={classNames(
        "relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800",
        disabled && "!bg-gray-500 hover:bg-gray-500",
        classes
      )}
      onClick={onClick}
      disabled={disabled}
      type={`${isSubmit ? 'submit' : 'button'}`}
    >
      <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
        {label || children}
      </span>
    </button>
  )
}