import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEmailTemplates({ emailTemplateId }) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, put } = useApiHelper();

  const emailTemplate = useQuery(
    ["emailtemplate"],
    () => get(`/emailtemplate/${emailTemplateId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation(
    (body) => put(`/emailtemplate/${emailTemplateId}`, { body: body }),
    {
      onSuccess: () => { 
        queryClient.invalidateQueries(["blogPosts", agency.id]);
      },
    }
  );

  return {
    emailTemplate,
    update
  };
}
