import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useRegistrations() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { user: { agency }, } = useUserContext();
  const { get, post, put, del, patch } = useApiHelper();

  const registrations = useQuery(["registrations", agency.id],() => get(`/agency/${agency.id}/registrations`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const archivedRegistrations = useQuery(["registrations", agency.id, "archived"],
    () => get(`/agency/${agency.id}/registrations/archived`).then((res) => res.data),
    {
      staleTime: staleTime
    }
  );

  const add = useMutation((registration) => post(`/agency/${agency.id}/registrations`, registration), {
    onSuccess: (addedRegistration) => {
      queryClient.setQueryData(["registrations", agency.id], (currentRegistrations) => [
        ...currentRegistrations,
        addedRegistration.data,
      ]);
    },
  });

  const update = useMutation((registration) => put(`/agency/${agency.id}/registrations/${registration.id}`, registration), {
    onSuccess: (updatedRegistration) => {
      queryClient.setQueryData(["registrations", agency.id], (currentRegistrations) =>
      currentRegistrations.map((registration) =>
        registration.id === updatedRegistration.data.id ? updatedRegistration.data : registration
        )
      );
    },
  });

  const remove = useMutation((registrationId) => del(`/agency/${agency.id}/registrations/${registrationId}`), {
    onSuccess: (_, registrationId) => {
      queryClient.setQueryData(["registrations", agency.id], (currentRegistrations) =>
      currentRegistrations.filter((registration) => registration.id !== registrationId)
      );
    },
  });

  const archive = useMutation((registrationIds) => patch(`/agency/${agency.id}/registrations/archive`, 
    { registrationLeadIds: registrationIds}), {
    onSuccess: () => {
      queryClient.invalidateQueries(["registrations", agency.id]);
    },
  });

  const undoArchive = useMutation((registrationIds) => patch(`/agency/${agency.id}/registrations/archive/undo`, 
    { registrationLeadIds: registrationIds}), {
    onSuccess: () => {
      queryClient.invalidateQueries(["registrations", agency.id]);
    },
  });

  return {
    registrations,
    archivedRegistrations,
    add,
    update,
    remove,
    archive,
    undoArchive,
  };
}
