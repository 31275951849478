import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useAiWriter() {
  const staleTime = 300000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const blogWriter = useMutation(
    (body) => post(`/aiblogwriter/prompt`, body),
    {
      onSuccess: (res) => {
        return res;
      },
    }
  );

  const review = useMutation(
    (body) => post(`/aiblogwriter/review`, body),
    {
      onSuccess: (res) => {
        return res;
      },
    }
  );


  return {
    blogWriter,
    review
  };
}
