import { useEffect, useState } from "react";
import { AdjustmentsIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import ComboBoxInput from "../Shared/Inputs/ComboBoxInput";
import useGoogleLocations from "../../data/useGoogleLocations";

export default function ConfigGoogle({ site, setIsConfig, handleAddReviewSite, setOpen, isUpdate, handleUpdateReviewSite }) {
  const { locations } = useGoogleLocations();
  const { register, handleSubmit, setValue, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    data.siteName = 0;
    if (isUpdate) {

      var updatedSite = {
        ...site,
        ...data
      };

      handleUpdateReviewSite(updatedSite)
    } else {
      data.locationId = data.locationId;
      handleAddReviewSite(data);
      setIsConfig(false);
    }
  };

  useEffect(() => {
    if (isUpdate) {

      reset({ ...site });
    }
  }, [site]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center">
        <div className="mr-5 flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
          <AdjustmentsIcon
            className="h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
        </div>
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Configure Google Reviews
        </Dialog.Title>
      </div>
      <div className="mt-3 sm:mt-5">
        <div className="mt-2">
          {loading || locations.isLoading ? (
            <div className="flex p-4 justify-center items-center">
              <LoadingWheel width="w-10" height="h-10" />
            </div>
          ) : (
            <div className="">
              <div className="p-3">
                <label
                  htmlFor="api"
                  className="block text-sm font-medium text-gray-700"
                >
                  API Key
                </label>
                <div className="mt-1">
                  <input
                    {...register("api")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="p-3">
                <label
                  htmlFor="PlaceId"
                  className="block text-sm font-medium text-gray-700"
                >
                  <span className="flex justify-between">
                    Place Id
                    <a target={"_blank"} href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" className="hover:cursor-pointer"><QuestionMarkCircleIcon className="h-6 w-6 text-blue-600" /></a>
                  </span>
                </label>
                <div className="mt-1">
                  <input
                    {...register("placeId")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="p-3">
                <div className="mt-1">
                  {/* <input
                    {...register("locationId")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  /> */}
                  <ComboBoxInput
                    label="Google Location ID"
                    options={locations.data}
                    searchField={"title"}
                    valueField="name"
                    register={register}
                    setValue={setValue}
                    registerName={"locationId"}
                    initialValue={site?.locationId}
                  />
                </div>
              </div>

              <div className="p-3">
                <label
                  htmlFor="requestReviewLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Request Review Link
                </label>
                <div className="mt-1">
                  <input
                    {...register("requestReviewLink")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="p-3">
                <label
                  htmlFor="reviewBranch"
                  className="block text-sm font-medium text-gray-700"
                >
                  Review Branch
                </label>
                <div className="mt-1">
                  <input
                    {...register("reviewBranch")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>

            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="w-full sm:w-1/2 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
          >
            Save
          </button>
          {isUpdate ? (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          ) : (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setIsConfig(false)}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </form >
  );
}