import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "react-query";
import { useForm, useFormState } from "react-hook-form";

import useAgencies from "../../data/useAgencies";
import LoadingWheel from "../Shared/LoadingWheel";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useFormValidation from "../../data/useFormValidation";
import SwitchInput from "../Shared/Inputs/SwitchInput";

export default function ServicesSettingsPartial({ agencyId }) {
  const { agencies, updateServicesSettings } = useAgencies();
  const { revalidate } = useFormValidation();
  const queryClient = useQueryClient();
  const { saveHandlers } = useDefaultCRUDHandlers("Report Settings");
  const { register, handleSubmit, getValues, setValue, reset, watch } = useForm({
    defaultValues: {
    }
  });
  const [agency, setAgency] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = (data) => {
    setIsLoading(true);

    var settings = {
      ...data,
    }

    console.log(settings)

    updateServicesSettings.mutate({ agencyId, settings }, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.refetchQueries(["serviceToggles", agencyId]);
      },
      onError: () => {
        saveHandlers.onError();
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });

    reset({
      ...data,
    });

    setIsLoading(false);
  }

  const loadAgency = useCallback((foundAgency) => {
    setAgency(foundAgency);

    // console.log(foundAgency)

    reset({
      ...foundAgency,
    });

    setIsLoading(false);
  }, [reset]);

  useEffect(() => {
    if (agencyId) {
      let agency = agencies.data.find((a) => a.id === agencyId);
      loadAgency(agency);
    }
  }, [agencies.data, agencies.isLoading, agencyId, loadAgency])

  if (isLoading || !agency) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <div className="">
          <div className="-mx-6 px-6">
            <h3 className="text-xl font-medium text-gray-900">
              Service Settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Toggle the services on or off for this agency
            </p>
          </div>

          <form className="mt-6" onSubmit={handleSubmit(onSubmit)} >

            <div className="grid grid-cols-2 gap-6 [&>div]:w-3/4">
              <SwitchInput label="Valuation Service" register={register} registerName="valuationServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Registration Service" register={register} registerName="registrationServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Enquiry Service" register={register} registerName="enquiryServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Viewing Service" register={register} registerName="viewingServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Portal Qualifier Service" register={register} registerName="portalQualifierServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Analytics Service" register={register} registerName="analyticsServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Mortgage Service" register={register} registerName="mortgageServiceToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Custom Forms/Form Builder" register={register} registerName="customFormsToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Use ET Policies" register={register} registerName="policiesToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Allow AI Usage" register={register} registerName="aiUsageToggle" setValue={setValue} watch={watch} />
              <SwitchInput label="Allow Team Bios" register={register} registerName="teamBioToggle" setValue={setValue} watch={watch} />
            </div>

            <div className="mt-10 col-span-2 flex justify-between">
              <button
                type="submit"
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Save
              </button>

              <button
                type="button"
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                onClick={() => revalidate.mutate(null, saveHandlers)}
              >
                Revalidate All Forms
              </button>
            </div>

          </form>
        </div>
      </>
    );
  }
}
