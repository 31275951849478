import { useCallback, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { BookOpenIcon, BriefcaseIcon, CalendarIcon, ChartSquareBarIcon, ChipIcon, ClipboardListIcon, CogIcon, CurrencyPoundIcon, DocumentReportIcon, LocationMarkerIcon, MailIcon, OfficeBuildingIcon, PuzzleIcon, StarIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/outline";

import AgencyProfileAddEditPartial from "../../components/Agency/AgencyProfileAddEditPartial";
import GroupListPartial from "../../components/Agency/GroupListPartial";
import ServerSettingsPartial from "../../components/Agency/ServerSettingsPartial";
import AreaGuidesPartial from "../../components/AreaGuides/AreaGuidesPartial";
import BranchListPartial from "../../components/Branch/BranchListPartial";
import BranchPostcodesPartial from "../../components/BranchPostcodes/BranchPostcodesPartial";
import DepartmentListPartial from "../../components/Department/DepartmentListPartial";
import IntegrationSettingsPartial from "../../components/Integrations/IntegrationSettingsPartial";
import PortalQualifer from "../../components/PortalQualifier/PortalQualifer";
import ReportsSettingsPartial from "../../components/Reports/ReportsSettingsPartial";
import ReviewConfig from "../../components/Review/ReviewConfig.js";
import ServicesSettingsPartial from "../../components/Services/ServicesSettingsPartial";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import TranspondSettingsPartial from "../../components/Transpond/TranspondSettingsPartial";
import UserListPartial from "../../components/User/UserListPartial";
import UserInviteListPartial from "../../components/UserInvite/UserInviteListPartial";
import ValuationSettingsPartial from "../../components/Valuations/ValuationSettingsPartial";
import useAgencies from "../../data/useAgencies";
import useAgencyServerSettings from "../../data/useAgencyServerSettings";
import classNames from "../../utils/classNames";
import SocialPostsPartial from "../SocialPosts/SocialPostsPartial";
import PolicyInfoPartial from "../../components/Agency/PolicyInfoPartial.js";


export default function AgencyEdit() {
  const navigate = useNavigate();
  const { agencyId, section } = useParams();
  const { agencies } = useAgencies();
  const { kinstaSettings } = useAgencyServerSettings(agencyId);
  const [agency, setAgency] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const loadAgency = useCallback(() => {
    if (!agencies.isLoading && agencies.data.length > 0 && !kinstaSettings.isLoading) {
      let agency = agencies.data.find((a) => a.id === agencyId);
      setAgency(agency);
      setLoading(false);
    }
  }, [agencies.isLoading, agencies.data, kinstaSettings.isLoading, kinstaSettings.data, agencyId]);

  const getPanel = (agency) => {
    switch (section) {
      case "kinsta-settings":
        return (
          <ServerSettingsPartial domain={agency.domain} agency={agency} agencyId={agency.id} />
        );
      case "policy-info":
        return <PolicyInfoPartial agencyId={agency.id} />;
      case "branches":
        return <BranchListPartial agencyId={agency.id} />;
      case "branch-postcodes":
        return <BranchPostcodesPartial agencyId={agency.id} />;
      case "area-guides":
        return <AreaGuidesPartial agencyId={agency.id} />;
      case "departments":
        return <DepartmentListPartial agencyId={agency.id} />;
      case "users":
        return (
          <div className="divide-y-2 divide-gray-200">
            <UserListPartial agencyId={agency.id} />
            <UserInviteListPartial agencyId={agency.id} />
          </div>
        );

      case "groups":
        return <GroupListPartial agencyId={agency.id} />;
      case "valuations":
        return <ValuationSettingsPartial agencyId={agency.id} />;
      case "reviews":
        return <ReviewConfig agencyId={agency.id} agencyLogicalName={agency.logicalName} />;
      case "portal-qualifier":
        return <PortalQualifer agencyId={agency.id} />;
      case "transpond-settings":
        return (
          <TranspondSettingsPartial
            domain={agency.domain}
            agency={agency}
            agencyId={agency.id}
          />
        );
      case "reports-settings":
        return <ReportsSettingsPartial agencyId={agency.id} />;
      case "services-settings":
        return <ServicesSettingsPartial agencyId={agency.id} />;
      case "integrations":
        return <IntegrationSettingsPartial agencyId={agency.id} />;
      case "social-posts":
        return <SocialPostsPartial agencyId={agency.id} />;
      default:
        return <AgencyProfileAddEditPartial agency={agency} />;
    }
  };

  useEffect(() => {
    setLoading(true);
    loadAgency();
  }, [agencies, agencyId, loadAgency]);

  const pages = [
    { to: "/admin/agencies", label: "Agencies", current: false },
    {
      to: "",
      label: agencyId && agency ? `${agency.name}` : "New Agency",
      current: true,
    },
  ];

  const subNavigation = () => {
    if (agencyId) {
      // if (kinstaSettings.data?.wordPressPassword && kinstaSettings.data?.wordPressUsername) {
      return [
        { icon: UserCircleIcon, name: "Profile", href: "" },
        { icon: CogIcon, name: "WordPress Settings", href: "kinsta-settings" },
        { icon: ClipboardListIcon, name: "Policy Info", href: "policy-info" },
        {
          icon: OfficeBuildingIcon,
          name: "Branches",
          href: "branches",
        },
        {
          icon: LocationMarkerIcon,
          name: "Branch Postcodes",
          href: "branch-postcodes",
        },
        {
          icon: DocumentReportIcon,
          name: "Area Guides",
          href: "area-guides"
        },
        {
          icon: BriefcaseIcon,
          name: "Departments",
          href: "departments",
        },
        // { icon: PresentationChartLineIcon, name: "Assigned Categories", href: "categories"},
        { icon: UsersIcon, name: "Users", href: "users" },
        { icon: UserGroupIcon, name: "Groups", href: "groups" },
        { icon: CurrencyPoundIcon, name: "Notifications", href: "valuations" },
        { icon: StarIcon, name: "Review Sites", href: "reviews" },
        {
          icon: ChartSquareBarIcon,
          name: "Portal Qualifier",
          href: "portal-qualifier",
        },
        {
          icon: MailIcon,
          name: "Email Marketing",
          href: "transpond-settings",
        },
        {
          icon: BookOpenIcon,
          name: "Dashboard Reports",
          href: "reports-settings",
        },
        {
          icon: ChipIcon,
          name: "Services Settings",
          href: "services-settings",
        },
        {
          icon: PuzzleIcon,
          name: "Integrations",
          href: "integrations",
        },
        {
          icon: CalendarIcon,
          name: "Social Posts",
          href: "social-posts",
        },
      ];
      // } else {
      //   return [
      //     { icon: UserCircleIcon, name: "Profile", href: "" },
      //     { icon: CogIcon, name: "Kinsta Settings", href: "kinsta-settings" },
      //   ];
      // }

    }
  };

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-10" height="h-10" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="sm:flex-auto my-4">
            <div className="flex justify-between">
              <h3 className="text-xl font-semibold text-gray-900">
                {agency ? `${agency.name}` : "New Agency"}
              </h3>
              <Link
                to={`/${agency.logicalName}`}
                className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                View Agency
              </Link>
            </div>
            <p className="mt-2 text-sm text-gray-700">
              {agency
                ? `View and edit ${agency.name}'s details`
                : "Enter the agencies details below"}
            </p>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="min-h-[256px] divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-2">
                <nav className="space-y-1">
                  {subNavigation().map((item) => {
                    let currentSection = section ? section : "";
                    let current = item.href === currentSection;
                    return (
                      <Link
                        key={item.name}
                        to={`${item.href !== "" ? `${item.href}` : ""}`}
                        className={classNames(
                          current
                            ? "bg-etpink-50 border-etpink-500 text-etpink-700 hover:bg-etpink-50 hover:text-etpink-700"
                            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                        )}
                        aria-current={current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            current
                              ? "text-etpink-500 group-hover:text-etpink-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </Link>
                    );
                  })}
                </nav>
              </aside>
              <div className="bg-gray-50 lg:col-span-10">
                <div className="h-full py-6 px-4 sm:p-6 lg:pb-8">
                  {getPanel(agency)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
