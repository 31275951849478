import { useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import LoadingWheel from "../Shared/LoadingWheel";
import SelectInput from '../Shared/Inputs/SelectInput';
import useTeamMembers from "../../data/useTeamMembers";

export default function BranchAddEditPartial({ handleSave }) {
  const [inviteEmail, setInviteEmail] = useState("");
  const [teamInviteEmail, setTeamInviteEmail] = useState("");
  const { nonUserTeamMembers } = useTeamMembers();
  const { register, setValue, watch } = useForm({
    defaultValues: { name: "Select a Team Member" },
  });

  const save = () => {
    handleSave({
      email: inviteEmail,
    });
  };

  const saveTeam = () => {
    handleSave({
      email: teamInviteEmail,
    });
  }

  return (
    <>
      <div className="block">
        <label
          htmlFor="InviteEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Email Address
        </label>
        <div className="mt-1">
          <input
            type="Text"
            name="InviteEmail"
            id="InviteEmail"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="joe.bloggs@myestateagency.com"
          />
        </div>
      </div>
      <div className="mt-8">
        <button
          type="button"
          onClick={save}
          className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
            Send Invite
        </button>
      </div>

      <div className="mt-8 mb-8 text-sm text-gray-500">Or</div>

      {nonUserTeamMembers.isLoading ? 
          <div className="flex p-10 bg-white justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
      : 
      <>
        <div className="block">
          {/* <label
            htmlFor="InviteEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Select Team Member
          </label> */}

          <SelectInput 
            label="Select Team Member" 
            registerName="teamMember" 
            options={nonUserTeamMembers.data} 
            register={register} 
            setValue={(key, val) => {
              var email = nonUserTeamMembers.data.find(e => e.id === val)?.emailAddress;
              setTeamInviteEmail(email);
              setValue(key, val);
            }} 
            watch={watch} 
          />

          {/* <div className="mt-1">
            <input
              type="Text"
              name="InviteEmail"
              id="InviteEmail"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="joe.bloggs@myestateagency.com"
            />
          </div> */}
          
        </div>
        <div className="mt-8">
          <button
            type="button"
            onClick={saveTeam}
            className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            Send Invite
          </button>
        </div>
      </>
      }
      
    </>
  );
}
