import { useEffect, useState } from "react";
import { PencilAltIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

import Flyout from "../../containers/Flyout";
import NotificationPolicyAddEditPartial from "./NotificationPolicyAddEditPartial";
import useNotificationPolicies from "../../data/useNotificationPolicies";
import ConfirmDelete from "../Shared/ConfirmDelete";

function PolicyListRow({ policy, onEditClick, onDeleteClick }) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="text-sm grow font-medium text-etpink-600 md:w-1/5">
          {policy.policyName}
        </p>
        <span
          className={`truncate px-3 font-medium text-sm md:ml-2 rounded-full ${policy.isActive
            ? "bg-green-100 text-green-500"
            : "bg-gray-100 text-gray-500"
            }`}
        >
          {policy.isActive ? "Active" : "Inactive"}
        </span>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        {
          policy.policyName !== "System Default" ? (
            <button
              onClick={() => onDeleteClick(policy.id)}
              className="mx-2 text-gray-400 hover:text-red-600"
            >
              <span className="sr-only">Delete</span>
              <TrashIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          ) : (
            <div className="w-10"></div>
          )
        }
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onEditClick(policy)}
          className="mx-2 text-gray-400 hover:text-gray-600"
        >
          <span className="sr-only">Edit</span>
          <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function NotificationListPartial({ agencyId }) {
  const { policies, add, addAll, update, remove } = useNotificationPolicies(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Policy");
  const [open, setOpen] = useState(false);
  const [editPolicy, setEditPolicy] = useState({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deletePolicy = (policyId) => {
    setDeleteId(policyId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const handleEditNotification = (notification) => {
    setEditPolicy(notification);
    setOpen(true);
  };

  const handleSaveNotification = (notification) => {
    console.log("notif", notification);
    if (notification.id) {
      update.mutate(notification, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      });
    } else {
      // All Branches
      if(notification.branchId == "00000000-0000-0000-0000-000000000000"){
        addAll.mutate(notification, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setOpen(false);
          },
          onError: saveHandlers.onError,
        });
      }else{
        add.mutate(notification, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setOpen(false);
          },
          onError: saveHandlers.onError,
        });
      }
      
    }
  };

  useEffect(() => { }, [policies.data]);

  return (
    <>
      <ConfirmDelete
        itemName="Policy"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <Flyout
        open={open}
        setOpen={setOpen}
        title={editPolicy.name ? editPolicy.name : "Add Notification Policy"}
      >
        <NotificationPolicyAddEditPartial
          policy={editPolicy}
          onSave={handleSaveNotification}
        />
      </Flyout>
      <h3 className="text-xl font-medium text-gray-900">
        Notification Policies
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Control who recieves notifications
      </p>
      <button
        type="button"
        onClick={() => {
          setEditPolicy({});
          setOpen(true);
        }}
        className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Add Policy
      </button>
      <ul className="border border-gray-200 bg-white rounded-md divide-y divide-gray-200 my-4">
        {policies.isLoading ? (
          <li key="branches-loading" className="py-4 flex justify-center">
            <LoadingWheel width="w-8" height="h-8" />
          </li>
        ) : policies.data.length > 0 ? (
          policies.data.map((policy, i) => (
            <PolicyListRow
              policy={policy}
              key={i}
              onEditClick={handleEditNotification}
              onDeleteClick={deletePolicy}
            />
          ))
        ) : (
          <li>
            <div className="py-5 text-center text-sm text-gray-400">
              No Notification Policies
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
