
import { Switch } from "@headlessui/react";
import classNames from "../../../utils/classNames";

export default function SwitchInput({ label, register, registerName, setValue, watch, column = false, classes = "" }) {

  const watchToggle = watch(registerName);

  return (
    <div className={classes}>
      <Switch.Group as="div" className={classNames(column ? "flex flex-col gap-y-2" : "flex justify-between")}>
        <span className="mr-10">
          <Switch.Label as="span" className="text-md text-gray-900" passive>
            {label}
          </Switch.Label>
        </span>
        <Switch
          {...register(registerName)}
          checked={watchToggle}
          onChange={(e) => setValue(registerName, e)}
          className={classNames(
            watchToggle ? 'bg-etpink-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              watchToggle ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  )
}