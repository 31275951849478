import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import date from "date-and-time";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import { CDN_URL } from "../../services/config";
import useVideoReviews from "../../data/useVideoReviews";
import useApiHelper from "../../services/useApiHelper";
import { useQueryClient } from "react-query";
import useUserContext from "../../contexts/UserContext";
import useAllMedia from "../../data/useAllMedia";
import TinyMCE from "../../components/Shared/TinyMCE";

export default function VideoReviewAddEdit() {
  const { user: { agency } } = useUserContext();
  const { media } = useAllMedia();
  const { reviewId } = useParams();
  const { videoReviews, add, update } = useVideoReviews();
  const navigate = useNavigate();
  const { register, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: {
      title: "",
      content: "",
      date: date.format(new Date(), "YYYY-MM-DDTHH:mm"),
      videoMediaId: "",
      thumbnailMediaId: "",
    },
  });
  const { post, formData } = useApiHelper();
  const queryClient = useQueryClient();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Video Review");
  const [review, setReview] = useState(undefined);
  const [content, setContent] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [loading, setLoading] = useState(true);
  const [thumbnailMediaOpen, setThumbnailMediaOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [videoMediaOpen, setVideoMediaOpen] = useState(false);

  const loadReview = useCallback(
    (review) => {
      setReview(review);
      !review.mediaId && setVideoSource("URL");

      setContent(review.content);

      reset({
        title: review.title,
        content: review.content,
        thumbnailMediaId: review.thumbnailId,
        videoMediaId: review.mediaId ? review.mediaId : review.url,
      });
      setLoading(false);
    },
    [reset]
  );

  const handleThumbnailMediaChange = (_, __, media) => {
    setValue("thumbnailMediaId", media.id, { shouldDirty: true });
    setThumbnailMediaOpen(false);
  };

  const handleVideoMediaChange = async (source, _, mediaObj) => {
    let value = mediaObj.id;

    if (source === "URL") {
      const file = await handleFile(mediaObj);

      if (!file || file.size === 0) {
        return;
      }

      await uploadThumbnail(file);

      value = mediaObj;
    } else {
      const videoFile = await createFile(`${CDN_URL}` + `${media.data.find((m) => m.id === mediaObj.id)?.path}`, mediaObj.filename)

      //Generate 3 thumbnails from video - fastest option from library for some reason
      generateVideoThumbnails(videoFile, 3).then(thumbnailArray => {

        //Create a new image file from thumbnail array
        createFile(thumbnailArray[1], "uploadedVideoThumbnail.jpg", false).then((file) => {
          //Upload to wordpress
          uploadThumbnail(file).then((res) => {

          })
        })
      }).catch((err) => {
        console.error(err);
      });
    }

    setVideoSource(source);
    setValue("videoMediaId", value, { shouldDirty: true });
    setVideoMediaOpen(false);
  };

  const uploadThumbnail = async (file) => {
    //Upload to thumbnail agency so it doesn't clutter media manager
    return new Promise((resolve, reject) => {
      var thumbAgency = "00000000-0000-0000-0000-000000000000"
      post(`/media/agency/${thumbAgency}`, {
        name: file.name,
        filename: file.name,
        type: file.type,
        size: file.size,
        altText: file.altText,
      })
        .then((res) => {
          //Then upload the actual file
          let fd = new FormData();
          fd.append("media", file, res.data.filename);
          formData(`/media/${res.data.id}/${agency.id}`, fd).then((_) => {
            queryClient.invalidateQueries(["allMedia", agency.id]);
            setValue("thumbnailMediaId", res.data.id, { shouldDirty: true });
            resolve(res.data);
          });
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  };

  async function handleFile(url) {
    if (url.toLowerCase().includes("youtube")) {
      //Strips out youtube video ID
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        var url = `https://vumbnail.com/${match[2]}.jpg`;
        return await createFile(url, match[2]);
      } else {
        return null;
      }
      //Vimeo doesn't work
      // } else if (url.toLowerCase().includes("vimeo")) {
      //   //strips out vimeo video ID
      //   var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      //   var parseUrl = url.match(regExp);
      //   var url = `https://vumbnail.com/${parseUrl[5]}.jpg`;

      //   return await createFile(url, parseUrl[5]);
    } else {
      return null;
    }
  }

  async function createFile(url, videoCode, proxy = true) {
    if (proxy) {
      url = `https://arcane-peak-78372.herokuapp.com/${url}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      },
    });

    const data = await response.blob();

    const metaData = {
      type: data.type,
    };

    return new File([data], `${videoCode}.jpg`, metaData);
  }

  const onSubmit = (data) => {
    setLoading(true);

    let updatedReview = {
      ...review,
      title: data.title,
      content: content,
      url: videoSource === "URL" ? data.videoMediaId : null,
      thumbnailId: data.thumbnailMediaId,
      mediaId: videoSource === "URL" ? null : data.videoMediaId,
      status: isDraft ? 3 : 0,
    };

    if (reviewId) {
      update.mutate(updatedReview,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setLoading(false);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        });
    } else {
      add.mutate(updatedReview,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        });
    }

    reset({
      title: data.title,
      content: content,
      thumbnailMediaId: data.thumbnailMediaId,
      videoMediaId: data.videoMediaId,
    });

    setReview(updatedReview);
  };

  const thumbnailMediaIdValue = watch("thumbnailMediaId");
  const videoMediaIdValue = watch("videoMediaId");

  useEffect(() => {
    if (!videoReviews.isLoading && !media.isLoading) {
      if (reviewId) {
        let foundReview = videoReviews.data.find((r) => r.id === reviewId);
        loadReview(foundReview);
      } else {
        setLoading(false);
      }
    }
  }, [
    videoReviews.isLoading,
    videoReviews.data,
    media.isLoading,
    reviewId,
    loadReview,
    register,
  ]);

  const pages = [
    { to: "..", label: "Reviews", current: false },
    {
      to: reviewId && review ? `${reviewId}` : "",
      label: reviewId && review
        ? `${review.title} Video Review`
        : "New Video Review",
      current: true,
    },
  ];

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          open={thumbnailMediaOpen}
          setOpen={setThumbnailMediaOpen}
          onInsert={handleThumbnailMediaChange}
        />
        <InsertMediaModal
          allowImage={false}
          open={videoMediaOpen}
          setOpen={setVideoMediaOpen}
          onInsert={handleVideoMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="sm:flex-auto my-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {reviewId
                ? `${review.title} Video Review`
                : "New Video Review"}
            </h3>
            <p className="mt-2 text-sm text-gray-700">
              {reviewId
                ? `View and edit ${review.title} Video Review`
                : "Create a new video review using the form below - Enter a YouTube URL or upload a video from the media manager, then choose a thumbnail from the media manager."}
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="my-4 w-full">
                  <label
                    htmlFor="Review Source"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("title")}
                      className="shadow-sm bg-white focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Review title"
                    />
                  </div>
                </div>

                <div className="my-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Content
                  </label>
                  <TinyMCE
                    value={content}
                    onChange={setContent}
                    height={300}
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2">

                  <div className="w-[90%] mx-auto">
                    <label className="mb-1 text-center block text-sm font-medium text-gray-700">
                      Video
                    </label>
                    {videoMediaIdValue ? (
                      videoSource === "URL" ? (
                        <iframe
                          title="Video"
                          src={videoMediaIdValue}
                          height=""
                          width=""
                          className="w-full h-60"
                        ></iframe>
                      ) : (
                        <video className="w-full h-60" controls>
                          <source src={`${CDN_URL}` + `${media.data.find((m) => m.id === videoMediaIdValue)?.path}`} type="video/mp4" />
                        </video>
                      )
                    ) : (
                      <div className="w-full h-60 border border-gray-500"></div>
                    )}
                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={() => setVideoMediaOpen(true)}
                        className="mt-2 inline-flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                      >
                        {!videoMediaIdValue ? "Choose" : "Change"}
                      </button>
                    </div>
                  </div>

                  <div className="w-3/4 mx-auto">
                    <label className="mb-1 text-center block text-sm font-medium text-gray-700">
                      Video Thumbnail
                    </label>
                    {thumbnailMediaIdValue ? (
                      <img
                        alt="Video Thumbnail"
                        src={`${CDN_URL}` + `${media.data.find((m) => m.id === thumbnailMediaIdValue)?.path}`}
                        className="w-full h-60 object-contain"
                      />
                    ) : (
                      <div className="w-full h-60 border border-gray-500"></div>
                    )}
                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={() => setThumbnailMediaOpen(true)}
                        className="mt-2 w-24 px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                      >
                        {!thumbnailMediaIdValue ? "Choose" : "Change"}
                      </button>
                    </div>
                  </div>

                </div>

              </div>

              <div className="mt-10">
                <div className="mx-auto">
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveBlogEntry px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishBlogEntry px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
