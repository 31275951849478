import classNames from "../../../utils/classNames"
import Tooltip from "../Tooltips"

export const TextInputWithTooltip = ({ label, tooltipText, register, registerName, disabled = false, cols = "sm:col-span-1" }) => {

  return (
    <div className={cols}>
      <Tooltip tip={tooltipText} additionalClasses="translate-x-1/4 -translate-y-1/2">
        <label className="text-sm font-medium text-gray-700">
          {label}
        </label>
      </Tooltip>
      <input
        {...register(registerName)}
        disabled={disabled}
        type="text"
        className={classNames(disabled ? "bg-gray-300" : "", "w-full block mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300 rounded-md")}
      />
    </div>
  )
}