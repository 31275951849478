import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useReviewsImporter(agencyId) {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const importReviews = useMutation((reviews) => post(`/Review/${agencyId}/csvimport`, reviews), {
    onSuccess: (newReviews) => {
      console.log(newReviews);
      queryClient.invalidateQueries(["reviews", agencyId]);
      return newReviews;
    },
  });

  const batchImportReviews = useMutation((reviews) => post(`/Review/${agencyId}/csv/import/batch`, reviews), {
    onSuccess: (newReviews) => {
      console.log(newReviews);
      queryClient.invalidateQueries(["reviews", agencyId]);
      return newReviews;
    },
  });


  return {
    importReviews,
    batchImportReviews,
  };
}
