import { useEffect, useState } from "react";

import date from 'date-and-time';
import { useNavigate } from "react-router-dom";

import { 
  PencilAltIcon, 
  SearchIcon, 
  TrashIcon,
  FilterIcon,
  ArchiveIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import useFormLeads from "../../data/useFormLeads";
import useForms from "../../data/useForms";
import ConfirmModal from './../../components/Shared/ConfirmModal';
import { formLeadStatusEnum, formTypeEnum } from './../../constants/enums';
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';
import Avatar from "../../components/Shared/Avatar";
import useUserContext from "../../contexts/UserContext";
import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ArchivedListBox from "../../components/Shared/ArchivedListBox";
import ActionButton from "../../components/Shared/ActionButton";
import Pagination from "../../components/Shared/Pagination";

function FormRow({ form: formLead, onDeleteClick, archiving, addToArchive }) {
  const { user: { agency } } = useUserContext();
  const navigate = useNavigate();
  const { forms } = useForms();
  const [form, setForm] = useState(forms.data?.find((f) => f.id === formLead.formId));
  const { undoArchive } = useFormLeads();

  useEffect(() => {
    // console.log(form.)
  }, [agency]);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteClick(formLead.id);
  };

  const handleUndoArchive = (e) => {
    e.stopPropagation();
    undoArchive.mutate([formLead.id]);
  }

  return (
    <tr
      onClick={() => navigate(`formlead/${formLead.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell"
        onClick={(e) => {
          if (archiving) e.stopPropagation();
        }}>
        {archiving && (
          <input 
            type="checkbox" 
            className="h-4 w-4 text-etpink-600 focus:ring-etpink-500 
            border-gray-300 rounded"
            onChange={(e) => {
              addToArchive(formLead.id, e.target.checked);
            }}
          />
        
        )}

        {formLead.userId &&
          <Avatar classes="w-10 h-10" userId={formLead.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {formLead.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Email</dt>
          <dd className="mt-1 truncate">
            {formLead.emailAddress}
          </dd>
          <dt className="sr-only sm:hidden">Form</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {form?.title}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {formLead.emailAddress}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {form?.title}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formTypeEnum[form?.type]}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${formLead.status === 0 || formLead.status === 1 ? ("text-yellow-600 bg-yellow-100") :
              (formLead.status === 2 ?
                ("text-blue-600 bg-blue-100")
                : ("text-green-600 bg-green-100")
              )}`}
        >
          {formLeadStatusEnum[formLead.status]}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {agency && formLead.sourceUrl?.replace(agency?.domain, "").replace("/landing/", "")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formLead.source}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(formLead.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formLead.followUpDate ? date.format(new Date(formLead.followUpDate), "DD/MM/YYYY") : "None"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          {formLead.archivedOn !== null && (
            <ActionButton onClick={handleUndoArchive} tooltip={"Undo Archive"} Icon={ArchiveIcon} />
          )}
          <ActionButton onClick={() => navigate(`formlead/${formLead.id}`)} tooltip="Edit" Icon={PencilAltIcon} />
          <ActionButton onClick={handleDelete} tooltip="Delete" Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}


export default function FormLeads({ }) {
  const navigate = useNavigate();
  const { forms } = useForms();
  const { formLeads: allFormLeads, remove, archive, archivedFormLeads } = useFormLeads();
  const { deleteHandlers } = useDefaultCRUDHandlers("Forms");
  const [showArchived, setShowArchived] = useState(false);
  const [formLeads, setFormLeads] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const [archiving, setArchiving] = useState(false);
  const [toArchive, setToArchive] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [nPages, setNPages] = useState(0);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const deleteForm = (formId) => {
    setDeleteId(formId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const toggleArchiving = (save = false) => {
    setArchiving(!archiving);
    if (!save) return;

    archive.mutate(toArchive, {
      onSuccess: () => {
        setToArchive([]);
      }
    });
  }

  const addToArchive = (id, checked) => {
    if (checked) {
      setToArchive([...toArchive, id]);
    } else {
      setToArchive(toArchive.filter((val) => val !== id));
    }
  }

  useEffect(() => {
    document.title = "EstateTrack Portal | Form Leads";
    if (!allFormLeads.isLoading && !forms.isLoading) {
      let listToFilter = showArchived ? archivedFormLeads : allFormLeads;

      let filter = listToFilter.data;

      filter = filter.filter((lead) =>
        lead.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        forms.data.find((form) => form.id === lead.formId).title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.source?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setNPages(Math.ceil(filter.length / recordsPerPage))

      filter = filter.slice(indexOfFirstRecord, indexOfLastRecord);

      setFormLeads(filter);
      setIsLoading(false);
    }
  }, [allFormLeads.isLoading, allFormLeads.data, forms.isLoading, searchTerm, currentPage, showArchived]);

  return (
    <>
      <ConfirmModal
        title={"Form Lead"} message={`Are you sure you want to delete this lead?`}
        open={confirmDeleteOpen} setOpen={setConfirmDeleteOpen} onConfirm={confirmDelete}
        confirmButtonText={"Delete"}
      />
      <div className="px-4 sm:px-6 lg:px-8">

        <div className="sm:flex sm:items-center">
          <div className="flex grow flex-col sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Form Leads
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              View and Manage the leads that come from your custom forms here.
            </p>
          </div>

          <div className="flex px-2 mt-2 ">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FilterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
              <span>Filter</span>
            </button>
          </div>

          <div>
            <div className="mt-2 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name, Form or Source"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {archiving && (
              <button
                type="button"
                onClick={() => toggleArchiving(false)}
                className="inline-flex items-center justify-center rounded-md border 
                border-transparent bg-gray-400 px-4 py-2 text-sm font-medium 
                text-white shadow-sm hover:bg-gray-500 focus:outline-none 
                focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto mr-4"
              >
                Cancel
              </button>
            )}

            <button
              type="button"
              onClick={() => toggleArchiving(archiving)}
              className="inline-flex items-center justify-center rounded-md border 
              border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium 
              text-white shadow-sm hover:bg-etpink-700 focus:outline-none 
              focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto mr-4 mt-2 "
            >
              {archiving ? "Save" : "Archive"}
            </button>

          </div>

          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to={isLandingPage ? "landingpage" : "form"}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add New Form
            </Link>
          </div> */}

        </div>

        {/* Filters hidden */}
        <div id="filters-section" className="pt-2 transition-all ease-in-out delay-250 duration-3000">
          {filterOpen && (
            <div className="grid grid-cols-5 gap-5">
              <ArchivedListBox setArchived={setShowArchived} />
            </div>
          )}
        </div>


        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : formLeads?.length > 0 ? (
            <>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {/* Avatar */}
                    </th>
                    <th
                      scope="col"
                      className="hidden !pl-6 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Name</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Email</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Form</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Type</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Status</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Source URL</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Source</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Date Created</span>
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      <span className="flex">Follow Up Date</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {formLeads.map((form) => (
                    <FormRow
                      key={form.id}
                      form={form}
                      onDeleteClick={deleteForm}
                      archiving={archiving}
                      addToArchive={addToArchive}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                nPages={nPages}
              />
            </>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="form lead"
                  hasButton={false}
                />
              ) : (
                <NoItems searching itemName="form lead" />
              )}
            </div>
          )}
        </div>

      </div>
    </>
  );
}