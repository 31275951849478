import { useCallback, useEffect, useState } from "react";

import { useForm, useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DepartmentSelect from "../../components/Department/DepartmentSelect";
import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import MultiSelectInput from "../../components/Shared/Inputs/MultiSelectInput";
import SelectInput from "../../components/Shared/Inputs/SelectInput";
import { TextInput } from "../../components/Shared/Inputs/TextInput";
import { TextInputWithTooltip } from "../../components/Shared/Inputs/TextInputWithTooltip";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import TinyMCE from "../../components/Shared/TinyMCE";
import useUserContext from "../../contexts/UserContext";
import useAgencyBranches from "../../data/useAgencyBranches";
import useAgencyDepartments from "../../data/useAgencyDepartments";
import useAgencyMedia from "../../data/useAgencyMedia";
import useTeamMembers from "../../data/useTeamMembers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import SwitchInput from './../../components/Shared/Inputs/SwitchInput';

import "react-quill/dist/quill.snow.css";
import useAgencyServiceToggles from './../../data/useAgencyServiceToggles';


export default function TeamMemberAddEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { media } = useAgencyMedia();
  const { user: { agency }, } = useUserContext();
  const { serviceToggles } = useAgencyServiceToggles(agency.id);
  const { teamMemberId } = useParams();
  const { teamMembers, add, update } = useTeamMembers();
  const { departments } = useAgencyDepartments(agency.id);
  const { branches } = useAgencyBranches(agency.id);
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: { branch: "", featuredMedia: null, order: 0, description: "", department: 0, bioToggle: false, customLink: "", customLinkButtonText: "", displayBranches: [] },
  });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Team Member");
  const [teamMember, setTeamMember] = useState(null);
  const [isDraft, setIsDraft] = useState(true);
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const loadTeamMember = useCallback((member, department, branches) => {
    setTeamMember(member);

    console.log("department", department)

    reset({
      ...member,
      branch: member.branchId,
      featuredMedia: member.mediaManagerId,
      department: department?.id != null ? department.id : 0,
      displayBranches: member.displayBranchIds
    });
  },
    [reset]
  );

  const onSubmit = (data) => {
    console.log("submitting", data);
    setIsLoading(true);
    let departmentName = null;

    if (data.department) {
      departmentName = departments.data.find((d) => d.id === data.department).name;
    }

    let member = {
      ...teamMember,
      ...data,
      branchId: branchValue,
      status: isDraft ? 3 : 0,
      mediaManagerId: data.featuredMedia,
      department: departmentName,
      publish: !isDraft
    }

    if (teamMemberId) {
      update.mutate(member,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            setIsLoading(false);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    } else {
      add.mutate(member,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    }

    reset({
      ...data,
    });

    setTeamMember({
      ...member,
      branch: branchValue,
      mediaManagerId: data.featuredMedia,
      department: departmentName,
      displayBranches: member.displayBranchIds
    });
  };

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("media");

    setTimeout(() => {
      setValue("featuredMedia", media.id, { shouldDirty: true });
      setFeaturedMediaOpen(false);
    }, 1000);
  };

  useEffect(() => {
    register("description");
    register("featuredMedia");
    if (!teamMembers.isLoading && !branches.isLoading && !departments.isLoading) {
      if (teamMemberId) {
        let foundMember = teamMembers.data.find((tm) => tm.id == teamMemberId);
        let foundDepartment = departments.data?.find((d) => d.name === foundMember.department && d.branchId === foundMember.branchId);
        loadTeamMember(foundMember, foundDepartment, branches);
      } else {
        setValue("branch", branches.data[0].id)
      }
    }
  }, [teamMembers.data, teamMembers.isLoading, branches.data, branches.isLoading, media.data, media.isLoading, departments.data, departments.isLoading, loadTeamMember])

  const pages = [
    { to: "..", label: "Team", current: false },
    {
      to: teamMemberId && teamMember ? `${teamMemberId}` : "",
      label: teamMemberId ? `${getValues("name")}` : "New Team Member",
      current: true,
    },
  ];

  const branchValue = watch("branch");
  const featuredMediaValue = watch("featuredMedia");
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (media.isLoading) return;
    const imagePath = media.data.find((m) => m.id === featuredMediaValue)?.path;
    if (imagePath) {
      const img = new Image();
      img.src = `${CDN_URL}${imagePath}`;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [media.data, featuredMediaValue]);

  if (teamMembers.isLoading || branches.isLoading || media.isLoading || branchValue === "" || isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-20 flex justify-center">
          <LoadingWheel width="w-20" height="h-20" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          open={featuredMediaOpen}
          setOpen={setFeaturedMediaOpen}
          onInsert={handleFeaturedMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="sm:flex-auto my-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {teamMemberId ? teamMember.name : "New Team Member"}
            </h3>
            <p className="mt-2 text-sm text-gray-700">
              {teamMemberId
                ? `View and edit ${teamMember.name}'s details`
                : "Enter your new team members details below"}
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 col-span-1 lg:pr-10 lg:col-span-3">

                <TextInput label="Name" register={register} registerName="name" />
                <TextInput label="Role" register={register} registerName="role" />

                <TextInput label="E-Mail" register={register} registerName="emailAddress" />
                <TextInput label="Phone Number" register={register} registerName="phoneNumber" />

                <div className="w-full">
                  <SelectInput label="Branch" registerName="branch" options={branches.data} register={register} setValue={setValue} watch={watch} />
                </div>
                <div className="w-full">
                  <MultiSelectInput label="Display Branches" registerName="displayBranches" options={branches.data} register={register} setValue={setValue} watch={watch} />
                </div>

                <div className="w-full">
                  <DepartmentSelect agencyId={agency.id} branch={branchValue} register={register} setValue={setValue} watch={watch} />
                </div>
                <TextInput label="Site Order" register={register} registerName="order" />

                <div className="">
                  <TextInput label="Video Url" register={register} registerName="videoUrl" />
                </div>


                <div className="lg:col-span-2">
                  <label
                    htmlFor="Description"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Bio/Description
                  </label>
                  <TinyMCE
                    value={getValues("description")}
                    onChange={(newDesc) => setValue("description", newDesc)}
                    height={300}
                  />
                </div>

                <SwitchInput label="Auto Bio" register={register} setValue={setValue} watch={watch} registerName="bioToggle" classes="col-span-2 w-1/4" />

                <TextInputWithTooltip label="Custom Link" tooltipText="This is only for agencies that want to use a specific bio page" register={register} registerName="customLink" />
                <TextInputWithTooltip label="Custom Link Button Text" tooltipText="This is only for agencies that want to use a specific bio page" register={register} registerName="customLinkButtonText" />
              </div>

              <div className="lg:mt-3">

                <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Featured Image
                  </label>
                  <div className="border h-60">
                    {featuredMediaValue && !media.isLoading && media.data ? (
                      <div className="h-full relative">
                        <img
                          src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                          className="h-full w-full object-contain"
                          alt="Featured Image"
                        />
                        <div className="text-sm text-gray-500 absolute top-[-20px] right-1">
                          {imageDimensions.width} x {imageDimensions.height} pixels
                        </div>
                      </div>
                    ) : (
                      <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaOpen(true)}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    {!featuredMediaValue ? "Choose" : "Change"}
                  </button>
                </div>

                <div className="my-[3.75rem] mx-auto">
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveTeamMember px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishTeamMember px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
