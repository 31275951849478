import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  DocumentTextIcon,
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useContentEnginePosts from "../../data/useContentEnginePosts";
import useContentEngineCategories from "../../data/useContentEngineCategories";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { postStatusEnum } from "../../constants/enums";
import useGroups from "../../data/useGroups";
import Tooltip from "../../components/Shared/Tooltips";
import ActionButtonContainer from "../../components/Shared/ActionButtonContainer";
import ActionButton from "../../components/Shared/ActionButton";

function ContentEnginePostsRow({ post, categories, groups, onDeleteClick }) {
  const [categoryNames, setCategoryNames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    var filtered = categories.filter(filterCategories);
    var catNames = [];

    //Retrieve array of category names
    filtered.forEach((cat) => {
      catNames.push(cat.name);
    });

    setCategoryNames(catNames);
  }, [post, categories]);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(post.id);
  };

  const filterCategories = (category) => {
    //Retrieve categories that match this post
    return post.blogPostCategories.some(
      (cat) => category.id === cat.categoryId
    );
  };

  return (
    <tr
      onClick={() => navigate(`designer/${post.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {post.title}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Status</dt>
          <dd className="mt-1 truncate">
            <span
              className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${post.status === 3
                ? "text-yellow-600 bg-yellow-100"
                : "text-green-600 bg-green-100"
                }`}
            >
              {postStatusEnum[post.status]}
            </span>
          </dd>
          <dt className="sr-only sm:hidden">Published</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {date.format(new Date(post.datePublished), "DD/MM/YYYY HH:mm")}
          </dd>
        </dl>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {groups.find((g) => g.id === post.groupId).name}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {categoryNames?.toString()}
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        <span
          className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${new Date(post.datePublished) > new Date() ?
            ("text-blue-600 bg-blue-100") :
            (post.status === 3
              ? "text-yellow-600 bg-yellow-100"
              : "text-green-600 bg-green-100")
            }`}
        >
          {new Date(post.datePublished) > new Date() ? postStatusEnum[2] : postStatusEnum[post.status]}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(post.datePublished), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ActionButtonContainer>
          <ActionButton onClick={() => navigate(`designer/${post.id}`)} tooltip={"Edit"} Icon={PencilAltIcon} />
          <ActionButton onClick={handleDeleteClick} tooltip={"Delete"} Icon={TrashIcon} />
        </ActionButtonContainer>
      </td>
    </tr>
  );
}

export default function ContentEnginePosts() {
  const navigate = useNavigate();
  const { contentEnginePosts: allContentEnginePosts, remove } =
    useContentEnginePosts();
  const { categories } = useContentEngineCategories();
  const { groups } = useGroups();
  const { deleteHandlers } = useDefaultCRUDHandlers("Blog post");
  const [contentEnginePosts, setContentEnginePosts] = useState(
    allContentEnginePosts
  );
  const [serachTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deletePost = (post) => {
    setDeleteId(post);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Admin | Content Engine";

    if (!allContentEnginePosts.isLoading) {
      setContentEnginePosts(
        allContentEnginePosts.data.filter((post) =>
          post.title.toLowerCase().includes(serachTerm.toLowerCase())
        )
      );
    }
  }, [allContentEnginePosts.isLoading, allContentEnginePosts.data, serachTerm]);

  return (
    <>
      <ConfirmDelete
        itemName="Blog Post"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Content Engine Posts
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all content engine posts.
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DocumentTextIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Blog post title"
                />
              </div>
              <button
                type="button"
                className="ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button>
            </div>
          </div>

          <div className="sm:mt-0 sm:ml-16 sm:flex-none space-x-4">
            <Link
              to="designer"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Post
            </Link>
            <Link
              to="designer"
              type="button"
              state={{ openAiModal: true }}
              className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
            >
              <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Generate AI Post
              </span>
            </Link>

          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {contentEnginePosts.isLoading ||
            categories.isLoading ||
            groups.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : contentEnginePosts.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Publish Date
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {contentEnginePosts.map((post) => (
                  <ContentEnginePostsRow
                    key={post.id}
                    post={post}
                    categories={categories.data}
                    groups={groups.data}
                    onDeleteClick={deletePost}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {serachTerm === "" ? (
                <NoItems
                  itemName="blog post"
                  onNewClick={() => navigate("designer")}
                />
              ) : (
                <NoItems searching itemName="blog post" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
