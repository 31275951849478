import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { highlight, languages } from 'prismjs/components/prism-core';
import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import useEmailTemplate from "../../data/useEmailTemplate";
import Editor from "react-simple-code-editor";
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

import TinyMCE from "../../components/Shared/TinyMCE";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";


export default function ViewEmailTemplate() {
    const { emailTemplateId } = useParams();
    const { updateHandlers } = useDefaultCRUDHandlers("Email Template");
    // const { emailTemplates: allEmailTemplates } = useEmailTemplates();
    const { emailTemplate, update } = useEmailTemplate({ emailTemplateId });
    const navigate = useNavigate();


    const [template, setTemplate] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);





    // const loadEmailTemplate = useCallback(
    //     (emailTemplate) => {
    //         setTemplate(emailTemplate);
    //     },
    //     []
    // );
    useEffect(() => {
        if (!emailTemplate.isLoading && emailTemplate.data) {
            setTemplate(emailTemplate.data);
        }
    }, [emailTemplate.isLoading, emailTemplate]);



    const pages = [
        { to: "/admin", label: "Email Template", current: false },
        {
            to: emailTemplate && emailTemplate ? `${emailTemplateId}` : "",
            label: emailTemplate ? emailTemplate.name : "Email Template",
            current: true,
        },
    ];

    const updateTemplate = () => {
        // convert body to base 64 before sending to server
        update.mutate(template.body, updateHandlers);
    };


    if (isLoading || emailTemplate.isLoading || !template) {
        return (
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="h-80 max-w-7xl flex items-center justify-center">
                    <LoadingWheel width="w-12" height="h-12" />
                </div>
            </div>
        );
    } else {
        return (
            <>

                <div className="px-4 sm:px-6 lg:px-8">
                    <Breadcrumbs pages={pages} />

                    <div className=" my-4">
                        <h3 className="text-xl font-semibold text-gray-900">
                            <button
                                className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 
                                border border-transparent text-sm leading-4 font-medium rounded-md 
                                shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none 
                                focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 float-right"
                                onClick={() => updateTemplate()}
                            >
                                Save Template
                            </button>
                            Subject: {template?.subject}

                        </h3>

                        <div
                            dangerouslySetInnerHTML={{ __html: emailTemplate?.body }}
                            className="emailPreview my-4 float-left"

                        >
                        </div>
                        <div className="my-4"
                            style={{
                                maxHeight: "800px",
                                overflow: "auto",
                                backgroundColor: "#f5f2f0",
                            }}>

                            {/*<Editor*/}
                            {/*    value={emailTemplate ? emailTemplate.body : ""}*/}
                            {/*    onValueChange={code => setEmailTemplate(emailTemplate => ({ ...emailTemplate, body: code }))}*/}
                            {/*    highlight={code => highlight(code, languages.js)}*/}
                            {/*    padding={10}*/}
                            {/*    style={{*/}
                            {/*        fontFamily: '"Fira code", "Fira Mono", monospace',*/}
                            {/*        fontSize: 12,*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <TinyMCE value={template.body} onChange={() => { }} />

                        </div>
                    </div>


                </div>
            </>
        );
    }
}

