import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import classNames from './../../../utils/classNames';

export default function MultiSelectInput({ label, register, registerName, options, setValue, watch }) {
  const selectValue = watch(registerName) || [];

  // console.log("selectValue", selectValue);

  const handleChange = (value) => {
    let newValue;
    if (selectValue.includes(value)) {
      newValue = selectValue.filter((v) => v !== value);
    } else {
      newValue = [...value];
    }
    // console.log("value", value);
    // console.log("newValue", newValue);
    setValue(registerName, newValue);
  };

  return (
    <div>
      <Listbox
        {...register(registerName)}
        value={selectValue}
        onChange={(value) => handleChange(value)}
        multiple
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="w-full relative">
              <Listbox.Button
                className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {Array.isArray(selectValue) && selectValue.length > 0
                    ? (
                        selectValue.length < 3
                        ? selectValue.map((val) => options.find((e) => e.id === val)?.name).join(', ')
                        : selectValue.length + " selected"
                    ): "None selected"}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((e) => (
                    <Listbox.Option
                      key={e.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-etpink-600"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={e.id}
                    >
                      <>
                        <span
                          className={classNames(
                            selectValue.includes(e.id) ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {e.name}
                        </span>
                        {selectValue.includes(e.id) && (
                          <span
                            className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-etpink-600"
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}