import { useEffect, useState } from "react";
import LoadingWheel from "../Shared/LoadingWheel";
import Modal from "../Shared/Modal";

import Papa from 'papaparse';
import useReviewsImporter from "../../data/useReviewsImporter";
import { DateTime } from "luxon";
import BranchListBoxAdmin from './../Shared/BranchListBoxAdmin';
import { UploadIcon } from "@heroicons/react/outline";

export default function ImportReviewsFromCsv({ agencyId, agencyLogicalName, open, setOpen }) {
  const { importReviews, batchImportReviews } = useReviewsImporter(agencyId);
  const [isLoading, setIsLoading] = useState(false);
  const [importSource, setImportSource] = useState("google");
  const [success, setSuccess] = useState(false);
  const [countToImport, setCountToImport] = useState(0);
  const [countImported, setCountImported] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [branch, setBranch] = useState(null);
  const [importedReviews, setImportedReviews] = useState([]);
  const [imported, setImported] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    setIsLoading(true);
    setError(false);
    setSuccess(false);
    const file = e.target.files[0];
    setFileName(file.name);
    // console.log(file)

    Papa.parse(file, {
      header: false,
      complete: (results) => {
        // console.log(results)
        var headers = getHeaders(results);
        var data = getData(results);
        // console.log(headers)
        // console.log(data)
        var csvContent = Papa.unparse({
          fields: headers,
          data: data
        })

        const parser = parseType();
        parser(csvContent);
      }
    });

  };

  //Extensible object literals for parsing different import sources in future
  const getHeaders = (results) => ({
    google: results.data[0]
  })[importSource]

  const getData = (results) => ({
    google: results.data.slice(1)
  })[importSource]

  const parseType = () => ({
    google: googleParser,
  })[importSource];

  const saveNewReviews = (newReviews, keyedReviews) => {
    console.log(branch);
    //check reviews created against newReviews length to determine success
    importReviews.mutate(newReviews, {
      onSuccess: (newReviewsResult) => {
        setCountToImport(newReviews.length ?? 0);
        setCountImported(newReviewsResult.data?.length ?? 0);
        setSuccessMessage(newReviewsResult.message ?? "");
        setSuccess(true);
        // display all reviews 
        displayImportedReviews(newReviewsResult.data, keyedReviews);
      },
      onError: () => {
        setError(true);
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });
  };

  const googleParser = (csv) => {
    Papa.parse(csv, {
      header: true,
      complete: (results) => {
        let reviews      = [];
        let keyedReviews = [];

        // console.log(results);
        results.data.forEach(review => {
          //convert to iso date using luxon
          //All of these formats are possible because Excel sucks
          let date = DateTime.fromFormat(review["Date"], "yyyy-MM-dd HH:mm:ss");

          if (!date.isValid) {
            date = DateTime.fromFormat(review["Date"], "dd-MM-yyyy HH:mm");
          }
          if (!date.isValid) {
            date = DateTime.fromFormat(review["Date"], "dd/MM/yyyy HH:mm");
          }

          if (!date.isValid){
            console.log("Invalid date format", review["Date"]);
            return;
          } 
            
          let standardizedDate = date.toISO().substring(0, 18);
          let key = (standardizedDate + review["Author"]).replace(/[^a-zA-Z0-9]/g, '');

          var review = {
            name: review["Author"] ?? "Anonymous",
            date: date.toISO(),
            starRating: review["Rating"],
            content: review["Review"],
            reviewUrl: review["Review Url"],
            site: "google",
            branchId: branch
          };

          if (key in keyedReviews) console.error("Key needs to be changed!");
          else {
            let reviewCopy = { ...review };
            keyedReviews[key] = reviewCopy;
            keyedReviews[key].success = false;            
            keyedReviews[key].date = date.toFormat("dd/MM/yyyy");
          }

          if (review.starRating) reviews.push(review);
        });

        saveNewReviews(reviews, keyedReviews);
      }
    });
  };

  const displayImportedReviews = (imported, keyedReviews) => {

    imported.forEach(review => {
      let standardizedDate = review.date.substring(0, 18);
      let key = (standardizedDate + review.name).toString().replace(/[^a-zA-Z0-9]/g, '');
      if(key in keyedReviews) {
        keyedReviews[key].success = true;
        keyedReviews[key].id = review.id;
      } else console.error("Key not found in keyedReviews", key);
    });

    console.log(keyedReviews);

    setImportedReviews(Object.values(keyedReviews));
  };

  useEffect(() => {
    console.log("Imported changed", importedReviews);
    if (importedReviews.length > 0) {
      setImported(true);
      console.log("Imported");
    }else {
      setImported(false);
      console.log("Not Imported");
    }
  }, [importedReviews]);

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-4xl"}>

      {isLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="h-80 max-w-4xl flex items-center justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        </div>
      ) : (
        <div className="mx-auto flex flex-col gap-y-4">
          <h1 className="text-lg">
            Upload CSV for Google reviews
          </h1>

          <div className="w-full flex items-end">
            <div className="grow mr-3">
              <BranchListBoxAdmin setBranch={setBranch} agencyId={agencyId} none={false} />
            </div>
          
            <input
              id="import"
              type="file"
              className="hidden pointer-events-none"
              onChange={handleFileChange}
            />
            <label htmlFor="import">
              <div
                className="inline-flex items-center content-center rounded-md border 
                border-transparent bg-etpink-600 px-8 py-2 text-l font-medium leading-4 
                text-white shadow-sm hover:bg-etpink-700 cursor-pointer"
                // onClick={() => document.getElementById('import').click()}
              >
                Choose File
                <UploadIcon className="w-5 h-5 ml-3" />
              </div>
            </label>
          </div>

          <div>
            {success && (
              <>
                <p className="text-lg text-gray-900">
                  {fileName} uploaded successfully! <br></br>
                  <small className="text-gray-600">
                    {countImported}/{countToImport} Reviews Imported
                  </small>
                </p>
                {/* <p className="text-lg text-green-500">{successMessage}</p> */}
              </>
            )}
            {error && <p className="text-lg text-red-500">Error - Import Unsuccessful</p>}
          </div>

          {imported && (
            <div>
              {/* <h1 className="text-lg mb-3 mt-5">Imported Reviews</h1> */}
              <div className="h-96 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="w-full">
                  <thead className="sticky top-0 bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Name</th>
                      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Date</th>
                      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Rating</th>
                      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Success</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {importedReviews.map((review, index) => (
                      <tr 
                        key={index} 
                        className={`${!review.success ? "text-gray-400" : "hover:bg-gray-100 hover:cursor-pointer"}`}
                        onClick={() => {
                          if(!review.success) return;
                          // set url to base site url
                          let url = window.location.origin;
                          // add agency logical name
                          url += `/${agencyLogicalName}`;
                          // add /reviews/review/reviewId
                          url += `/reviews/review/${review.id}`;
                          window.open(url);
                        }}
                      >
                        <td className="px-4 py-2">{review.name}</td>
                        <td className="px-4 py-2">{review.date}</td>
                        <td className="px-4 py-2">{review.starRating}</td>
                        {review.success ? (
                          <td className="px-4 py-2 text-green-500">Success</td>
                        ) : (
                          <td className="px-4 py-2 text-red-200">Failed</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          

        </div>
      )}


    </Modal>
  )

}