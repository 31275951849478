import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useEmails from "../../data/useEmails";
import { EyeIcon, SearchIcon } from "@heroicons/react/outline";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ActionButtonContainer from "../Shared/ActionButtonContainer";
import ActionTextButton from "../Shared/ActionTextButton";



export default function AnniversaryEmailsModal({ open, setOpen }) {
  const { deleteHandlers } = useDefaultCRUDHandlers("Emails");
  const { emails, remove } = useEmails("Happy Anniversary", false);
  const [searchTerm, setSearchTerm] = useState("");

  const formatDate = (date) => new Date(date).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
  const removeEmail = (email) => remove.mutate(email.id, deleteHandlers);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-5xl sm:w-full sm:p-6">

                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="text-xl font-semibold text-gray-900">Anniversary Emails</h1>
                    <p className="mt-2 mb-6 text-sm text-gray-700">
                      View and cancel scheduled anniversary emails.
                    </p>
                  </div>

                  <div>
                    <div className="w-60 relative py-6 flex items-stretch flex-grow focus-within:z-10">
                      <div className="absolute py-6 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                        placeholder="Email address"
                      />
                    </div>
                  </div>
                </div>

                {emails.isLoading ? <p>Loading...</p>
                  : (
                    <div className="h-96 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                      <table className="w-full">
                        <thead className="sticky top-0 bg-gray-50 z-10">
                          <tr>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Email</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Send Date</th>
                            <th className="relative py-3.5 pl-3 pr-4 sm:pr-6"><span class="sr-only">Actions</span></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {emails.data.filter(email => email.to.includes(searchTerm)).map((email, index) => (
                            <tr
                              key={index}
                              className="hover:bg-gray-100 hover:cursor-pointer"
                              onClick={() => { }}
                            >
                              <td className="px-4 py-2">{email.to}</td>
                              <td className="px-4 py-2">{email.scheduledSendDate ? formatDate(email.scheduledSendDate) : email.scheduledSendDate}</td>
                              <td className="px-4 py-2">
                                <ActionButtonContainer>
                                  <ActionTextButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeEmail(email);
                                    }}
                                    text={"Stop email"}
                                  />
                                </ActionButtonContainer>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

}