import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";
import { useParams } from "react-router-dom";

export default function useTeamMembers(disableNonUser = false) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { agencyId } = useParams();
  const { get, post, put, del } = useApiHelper();

  const currentAgencyId = agency ? agency.id : agencyId;

  const teamMembers = useQuery(["teamMembers", currentAgencyId], () => get(`/TeamMembers/agency/${currentAgencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const nonUserTeamMembers = useQuery(["nonUserTeamMembers", currentAgencyId], () => get(`/TeamMembers/agency/${currentAgencyId}/nonUser`).then((res) => res.data),
    {
      staleTime: staleTime,
      enabled: !disableNonUser,
    }
  );

  // reorderRequest [
  //   { id: x, order: x },
  // ]
  const reorder = useMutation((reorderRequest) => put(`/TeamMembers/agency/${currentAgencyId}/reorder`, reorderRequest), {
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers", currentAgencyId]);
    },
  });

  const add = useMutation((teamMember) => post(`/TeamMembers/${teamMember.branch}`, teamMember), {
    onSuccess: (addedMember) => {
      queryClient.invalidateQueries(["teamMembers", currentAgencyId]);
    },
  });

  const update = useMutation((teamMember) => put(`/TeamMembers/${teamMember.branch}/${teamMember.id}`, teamMember), {
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers", currentAgencyId])
    },
  }
  );

  const remove = useMutation((teamMemberId) => del(`/TeamMembers/${currentAgencyId}/${teamMemberId}`),
    {
      onSuccess: (_, memberId) => {
        queryClient.invalidateQueries(["teamMembers", currentAgencyId]);
      },
    }
  );

  return {
    teamMembers,
    nonUserTeamMembers,
    add,
    update,
    remove,
    reorder,
  };
}
